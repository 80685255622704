import React,{useState,useEffect} from "react";
import {Section,TitleComponent,Tabs, SliderComponent, StatisticsComponent, NumberList} from "../../components";
import "./styles.scss";
import { history } from "../../config/stores";
import { useDispatch } from "react-redux";
import { setRef } from "../../actions";

const pictureMap = {
    "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-explore-tab.png",
    "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/promoter-smart-profile.png",
    "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/promoter-payments.png",
    "Step 4": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Promoter-chat.png"
  };

const Promoter = () =>{
    const [selectedTab, setSelectedTab] = useState("Explore tab");
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    const dispatch = useDispatch();

    const dataPromoters = [
      { img: 'https://fightscout.s3.amazonaws.com/uploads/Gzv6Fm_lyLqhMhgFHFhH2.jpg', title: 'Gamma - Global A...' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FbWKR7d-SGbTQjn1jt-2xr.jpg', title: 'King of Kings' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FJPx2xDma8L6omAQOtLkGO.jpg', title: 'AFL' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2F7SdsSSZDZKUtJt_urMcns.jpg', title: 'Spartans K1' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FUZigoWuLse4NQgWIZ1JDo.jpg', title: 'LG Sports & E...' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/AFSx2MD6OpXw_OKzX6LBW.jpg', title: 'MMA Series' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/Re5dChTlzcf_MWtI35HJS.jpg', title: 'Ultimate fight league' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/EsXTADuy0jbbdx-_-5jCL.jpg', title: 'Internatio… Red Dragon...' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/WDxdTXWoFb_9HpAMgfIKd.jpg', title: 'PGF (Professio...' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FZrhAPpNmfj_MihqO3qNll.jpg', title: 'MMAA - CZECH M...' },
    ]

    // const dataPromoters = [
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Elena Solano', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'John Tompson', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' }
    // ]

    const dataEvent = [
      { 
      title: 'Required steps',
      sectionData: [
        { description: 'Name, date and short description', label: 'General information' },
        { description: 'Banner, gallery and useful links', label: 'Media and links' } ]
      },
      { 
      title: 'Optional steps',
      sectionData: [ 
        { button: true, description: 'Turn on the livestream functionality', label: 'Watch live', learnMore: '/innovations' },
        { description: 'List all the fighters in this event', label: 'Confirmed fighters' },
        { description: 'Recruit fighters and crew', label: 'Opened positions' } ]
      },
    ]

    const options = [
      { name: "Registered", value: "782", profiles: true },
      { name: "New profiles this month", value: "34" },
      // { name: "Connections made", value: "302" },
    ];

    const recruitData = [
      "if you have opened positions, you can turn on recruitment,  While you are creating the event or through the edit",
      "To add a position all you have to do is describe what you are looking for. You can add as many as you want",
      "All Fighters and managers will see your listed positions and will be able to apply",
      "You can review the applications for each position in the app or download a pdf with all of them",
      "Applications can be accepted or declined directly through the app"
    ]

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },[])

    return(
    <div className="profile-container">
    {isMobileVersion === 1 ? 
      <Section
      video={"/promoter.mp4"}
      mobileTitle
      mobile={isMobileVersion}
      noAlign={1}
      title="CREATE YOUR PROFILE"
      invert
      height={`fit-content`}
      profile={"mobile-promoter"}
      renderContent={()=>{return(<>
          <div className="line-and-text mb-3 padding">
             <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
             <p style={{ fontSize: "18px" }}>
               <b>Fight Scout</b> is the first App that allows you to create and manage a professional profile that makes you stand out and build the ultimate career. Add crucial information to your profile that makes you stand out:
             </p> 
          </div>  

          <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8 padding">
                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Fighting styles</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Based in</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Official website</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Social media links</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Professional photos</p>
                  </div>
                </div>
        
         </>)            
     }}
 /> :
        <Section
             video={"/promoter.mp4"}
             halfVideo={true}
             title="CREATE YOUR PROFILE"
             invert
             blended
             profile={"promoter"}
            //  fixedHeight
            heightStretch
             renderContent={()=>{return(<>
                 <div className="line-and-text">
                    <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
                    <br/>
                    <p>
                      <b>Fight Scout</b> is the first App that allows you to create and manage a professional profile that makes you stand out and build the ultimate career. Add crucial information to your profile that makes you stand out:
                    </p> 
                  </div>  

                  <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8">
                  <div className="box-wrapper">
                    <p className="tab-text smaller">Fighting styles</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Based in</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Official website</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Social media links</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Professional photos</p>
                  </div>
                </div>
               
                </>)            
            }}
        />
    }
    
    {isMobileVersion === 1 ? 
   <>
    <SliderComponent
      title="FRESH FROM THE APP"
      subtitle="Say hello to this  month’s new faces"
      profile
      smallSlides={true}
      data={dataPromoters}
     />
   </> :  
   <SliderComponent
   title="FRESH FROM THE APP"
   subtitle="Say hello to this month’s new faces"
   smallSlides={true}
   profile
   data={dataPromoters}
  />}

        {isMobileVersion === 1 ? (
        <>
          <TitleComponent
            title={"CREATE YOUR EVENT"}
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h2 className="uppercase" style={{ fontSize: "18px", marginLeft: "5%",marginTop:"0%" }}>
            Never worry about finding a fighter again 
          </h2>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Create-event-phone.png"
            reversed
            title={""}
            mobile
            renderContent={() => {
              return (
                <div className="line-and-text padding">
                  <div className="gap flex flex-col gap-5 content-start" style={{ marginBottom: "15%" }}>
                    {dataEvent?.map((data, index) => {
                      return (
                        <div className="text-section-wrapper flex flex-col" key={`${index}-option`}>
                          <div className="flex items-center justify-start gap-3 mb-4">
                            <div className="circle w-4 h-4 rounded-full border-1 border-red bg-black" />
                            <h3 className="section-title">{data?.title}</h3>
                          </div>

                          <div className="main-data-wrapper flex flex-col">
                            {data?.sectionData?.map((el, sectionIndex) => {
                              return (
                                <div className="row-wrapper">
                                  <div className={`option flex-row justify-between rounded-md content-center border-2 w-full ${el?.button ? 'border-red' : ''}`} key={`${sectionIndex}-option`}>
                                    <div className="name uppercase">{el?.description}</div>
                                    <div className={`value uppercase ${el?.button ? 'text-red' : ''}`}>{el?.label}</div>
                                  </div>

                                  {el?.button && 
                                  <h4
                                  className=" text-right underline uppercase text-red text-md fontFamily-light cursor-pointer"
                                  onClick={() => {
                                    dispatch(setRef('stream and watch events'))
                                    history.push(el?.learnMore)
                                    }}>Learn more about this functionality</h4>}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                </div>
              );
            }}
          />
        </>
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Create-event-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Create-event-background.png")`}
          title="CREATE YOUR EVENT"
          reversed
          center
          heightStretch
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title uppercase">
                    Never worry about finding a fighter again 
                  </h3>
                  <br />

                  <div className="gap flex flex-col gap-15">
                    {dataEvent?.map((data, index) => {
                      return (
                        <div className="text-section-wrapper flex flex-col" key={`${index}-option`}>
                          <div className="flex items-center justify-start gap-3 mb-4">
                            <div className="circle w-4 h-4 rounded-full border-1 border-red bg-black" />
                            <h3 className="section-title">{data?.title}</h3>
                          </div>

                          <div className="main-data-wrapper flex flex-col">
                            {data?.sectionData?.map((el, sectionIndex) => {
                              return (
                                <div className="row-wrapper">
                                  <div className={`option flex-row justify-between rounded-md content-center border-2 w-full ${el?.button ? 'border-red' : ''}`} key={`${sectionIndex}-option`}>
                                    <div className="name uppercase">{el?.description}</div>
                                    <div className={`value uppercase ${el?.button ? 'text-red' : ''}`}>{el?.label}</div>
                                  </div>

                                  {el?.button && 
                                  <h4
                                  className=" text-right underline uppercase text-red text-md fontFamily-light cursor-pointer"
                                  onClick={() => {
                                    dispatch(setRef('stream and watch events'))
                                    history.push(el?.learnMore)
                                    }}>Learn more about this functionality</h4>}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                </div>
              </>
            );
          }}
        />
      )}


    <StatisticsComponent
        title="PROMOTERS IN THE APP"
        subtitle="This is what our users are saying"
        options={options}
        profile
        profileType='promoter'
        height={100}
       />

    {isMobileVersion === 1 ? 
      <>
      <TitleComponent title={"RECRUIT FIGHTERS DIRECTLY IN THE APP"} smallerTitle={1} marginleft={5} titleMargin={5}/>
      <h3 className="lower-title" style={{fontSize:"18px",marginLeft:"5%"}}>NEVER WORRY ABOUT FINDING A FIGHTER AGAIN</h3>
      <Section
      mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Recruit-phone.png"
      // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Recruit-background.png")`}
      title={""}
      reversed
      mobile
      renderContent={()=>{return(
                  <NumberList
                  data={recruitData}
                  learnMore='/innovations'
                  scrollRef='stream and watch events'
                   />)
        
      }}
      /> 
      </>
      :
        <Section
        mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Recruit-phone.png"
        image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Recruit-background.png")`}
        title="RECRUIT FIGHTERS DIRECTLY IN THE APP"
        reversed
        heightStretch
        renderContent={()=>{return(<>
                <div className="line-and-text">
                    <h3 className="lower-title">NEVER WORRY ABOUT FINDING A FIGHTER AGAIN</h3>
                    <br />

                  <NumberList
                  data={recruitData}
                  learnMore='/innovations'
                  scrollRef='stream and watch events'
                   />
                </div>  
          </>)
          
        }}
        />
   }

      <div className="tabs-component-container">
      {window.innerWidth < 900 ? (
        <>
        <div className = "mobile-tabs-container">
        <h2 className="upper-mobile-title" style={{marginLeft:"5%"}}>UNIQUE FUNCTIONS</h2>
        <h5 className="inner-mobile-title" style={{marginLeft:"5%"}}>FIND WHAT YOU NEED WITH FIGHT SCOUT</h5>
          <div className="background-tab">Explore tab</div>
          <Section
              mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Promoter-explore-tab.png"
              // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-functions-background.png")`}
              mobile={isMobileVersion}
              reversed
              title={""}
              renderContent={()=>{return(<>
                <p className="normal-tab-text">As a promoter, you are constantly on the look out for sponsors, 
                fighters, support individuals like MC's and Cutman, and you are also interested to know what your competitors are up to. 
                When you click the "Explore" tab, you will be able to search for just about anything. 
                Whether you want to find out if there are other events in your area, or on particular date and time, 
                or you want to find the best referee and MC to make your event the success it deserves to be, 
                simply use the smart search engine of <b>Fight Scout</b> and get the information you need right away. 
                Find a manager to take care of your fight card, or find a fighter and connect directly with them, it's all in here. </p>
                </>)
                
              }}
        /> 
      <div className="background-tab">Smart profile</div>
          <Section
              mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/promoter-smart-profile.png"
              // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-functions-background.png")`}
              mobile={isMobileVersion}
              reversed
              title={""}
              renderContent={()=>{return(
                <p className="normal-tab-text">Control and manage your profile and let everyone in the industry view the information you like them to see. 
                Choose a preferred language and break the boundaries of communication difficulties with people from other parts of the world. 
                Select the best images and videos, or add the links to your social media channels and websites 
                of your choice and let people know everything about your promotion. <b>Fight Scout</b> helps you get the attention you deserve.
                </p>)
              }}
      /> 
      <div className="background-tab">Connections</div>
      <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/promoter-payments.png"
          // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-functions-background.png")`}
          mobile={isMobileVersion}
          reversed
          title={""}
          renderContent={()=>{return(
            <p className="normal-tab-text"><b>Fight Scout</b> will display all the connections you have with other users of the app, 
            creating your unique portfolio and helping others know more about your activities and success.</p>
            )
            
          }}
      /> 
      <div className="background-tab">Chat</div>
      <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Promoter-chat.png"
          // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-functions-background.png")`}
          mobile={isMobileVersion}
          reversed
          title={""}
          renderContent={()=>{return(
            <p className="normal-tab-text"><b>Fight Scout</b> chat function aims to provide you with a communication tool 
            that is focused on your business only. Having difficulties scrolling through hundreds of emails, 
            or social media chats with people you work with among all the other messages you receive there? 
            Well, <b>Fight Scout</b> is business only chat. 
            Choose who can chat with you and communicate only with the people you work with or wish to work with and have it all 
            simplified in one app.
              </p>)
          }}
      /> 

        </div>
        </>
      ) : (
        <>
          <div className="content-container not-blend">
            <div className="text-inner-container">
              <TitleComponent title={"GET THE MOST OUT OF FIGHT SCOUT"} />
              <h3 className="lower-title" style={{marginLeft:"5%"}}>FIND WHAT YOU NEED WITH FIGHT SCOUT</h3>
              <Tabs
                tabs={["Explore tab", "Smart profile", "Payments", "Chat"]}
                selected={selectedTab}
                onSelect={(value) => setSelectedTab(value)}
              />
              {selectedTab === "Explore tab" ?
              <>
                <p className="normal-tab-text">
                  As a promoter, you are constantly on the look out for sponsors, fighters and support. You are also interested to know what your competitors are up to. When you click the “Explore” tab, you will be able to search for just about anything. 
                </p> 
                <br />
                <p className="normal-tab-text">
                  Whether you want to find out if there are other events in your area, or on particular date and time, or you want to find the best fighters to make your event the success it deserves to be, simply use the smart search engine of Fight Scout and get the information you need right away. Find a manager to take care of your fight card, or find a fighter and connect directly with them, it’s all in there. 
                </p>
              </> :

              selectedTab === "Smart profile" ?
              <>
              <p className="normal-tab-text">
              Control and manage your profile and let everyone in the industry see the information you like them to see. Choose a preferred language and break the boundaries of communication difficulties with people from other parts of the world. Select the best images and videos, or add the links to your social media channels and websites of your choice and let people know everything about your career. <b>Fight Scout</b> helps you get the attention you deserve.</p>
              </>  :
              selectedTab === "Payments" ? <>
              <p className="normal-tab-text"><b>Fight Scout</b> now allows you to monetise your events through the app and reach new fans all over the world. To earn money from views, you need to set up a livestream and air your event directly in the app. You set the price, we get the audience. After that, each month we will initiate a payout with your earnings and you can track everything in your payout history in your profile.</p>
              <h4 className="red-link text-left underline uppercase text-red text-md fontFamily-light cursor-pointer mt-5" 
              onClick={() => {
                dispatch(setRef('stream and watch events'))
                history.push('/innovations')
                }}>Learn more about this functionality</h4>
              </> : 

              selectedTab === "Chat" ? <>
              <p className="normal-tab-text"><b>Fight Scout</b> chat function aims to provide you with a communication tool that is focused on your business only. Having difficulties scrolling through hundreds of emails, or social media chats with people you work with among all the other messages you get there? Well, Fight Scout is business only chat. Choose who can chat with you and communicate only with the people you work with or wish to work with and have it all simplified in one app.</p>
              </> : ""}
            </div>
          </div>  
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-unique-functions-background.png")`,
              }}
            >
              <img 
              className="main-image"
              src={selectedTab === "Explore tab" ? pictureMap["Step 1"] :
              selectedTab === "Smart profile" ? pictureMap["Step 2"] :
              selectedTab === "Payments" ? pictureMap["Step 3"] :
              selectedTab === "Chat" ? pictureMap["Step 4"] : ""} />
            </div>
        </>
      )}
    </div>

    </div>
    );
}

export default Promoter;