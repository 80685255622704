import React, { useState, useEffect } from "react";
import {
  Section,
  TitleComponent,
  ContactComponent,
  NewsComponent,
} from "../../components";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { articlesList, articleById } from "../../actions";
import "./styles.scss";
import moment from "moment";

const Blog = ({ allBlogs, currentBlog, articlesList, articleById }) => {
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    articlesList({
      pageNumber: 1,
      pageSize: 10,
      noPagination: true,
      search: "",
    });
  }, []);


  useEffect(() => {
    if (allBlogs?.length) articleById(allBlogs[0]?._id)
  }, [allBlogs]);

  // console.log(allBlogs)

  return (
    <div className="property-component-container">
      {isMobileVersion === 1 ? (
        <>
          {/* <TitleComponent
            title={allBlogs[0]?.title}
            mobile
            titleMargin={5}
          />
          <h3 className="mt-7 mb-3 font-light" style={{ fontSize: "18px", padding: "0 20px" }}>
            {moment(allBlogs[0]?.createdAt).format("DD.MM.YYYY")}
          </h3>
          <Section
            mainImage={allBlogs[0]?.coverPhoto?.address}
            mobile={isMobileVersion}
            invert
            title={""}
            renderContent={() => {
              return (
                <>
                  <div className="line-and-text padding mt-3">
                    <p className="normal-tab-text">
                      The term ‘martial arts’ refers to numerous systems and
                      traditions for training and combat that are practiced for
                      many reasons including self-defence, competition, military
                      and law enforcement as well as to work on one’s mental,
                      spiritual and physical development. It’s also an
                      incredibly popular way to stay in shape and keep on top of
                      your fitness, health and wellbeing.
                    </p>
                  </div>
                  <div className="button-outer-wrapper flex flex-col items-center content-center w-full padding">
                    <div
                      className="button-container mobilesection"
                      onClick={() => {
                        history.push({ pathname: "/article", search: `/${allBlogs[0]?._id}` });
                      }}>
                      READ MORE
                    </div>
                  </div>
                </>
              );
            }}
          /> */}
        </>
      ) : (
        <>
          <Section
            image={`url(${allBlogs[0]?.coverPhoto?.address})`}
            title={allBlogs[0]?.title}
            invert
            height={100}
            renderContent={() => {
              return (
                <>
                  <div className="line-and-text">
                    <h5>{moment(allBlogs[0]?.createdAt).format("DD.MM.YYYY")}</h5>
                    {currentBlog?.text &&
                    <div className="article-text preview" dangerouslySetInnerHTML={{ __html: currentBlog?.text }}></div>}
                  </div>
                  <div
                    className="button-container"
                    style={{fontSize:"14px"}}
                    onClick={() => {
                      history.push({
                        pathname: "/article",
                        search: `/${allBlogs[0]?._id}`,
                      });
                    }}
                  >
                    READ MORE
                  </div>
                </>
              );
            }}
          />
        </>
      )}
      <NewsComponent allBlogs = {allBlogs} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  allBlogs: state.blogs.allBlogs,
  currentBlog: state.blogs.currentBlog,
});
const mapDispatchToProps = (dispatch) => ({
  articlesList: (payload) => dispatch(articlesList(payload)),
  articleById: (payload) => dispatch(articleById(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Blog);
