import { createStore, applyMiddleware,compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import reducers from '../reducers'

import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from '../epics'

const epicMiddleware = createEpicMiddleware()
const history = createBrowserHistory();

const middleware = []
middleware.push(routerMiddleware(history))
middleware.push(epicMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore)
const configureStore = () => {
  const store = createStoreWithMiddleware(reducers)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  epicMiddleware.run(rootEpic)
  return store
}

export {
  configureStore,
  history
}
