import React, { useState, useEffect } from "react";
import { Section, TitleComponent, Tabs, SliderComponent, NumberList, StatisticsComponent } from "../../components";
import "./styles.scss";

const pictureMap = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-explore-tab.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-smart-profile.png",
  "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-manager.png",
  "Step 4": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-coach.png",
  "Step 5": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-chat.png",
  "Step 6": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-status.png",
};

const Fighter = () => {
  const [selectedTab, setSelectedTab] = useState("Explore tab");
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  const opportunitiesData = [
    "Discover events with recruitment",
    "Browse all the opened positions",
    "If you meet the criteria you can apply",
    "The promoter will review your application and respond in the app"
  ]

  const documentsData = [
    "You can add any event you are participating in directly to your Profile",
    "Based on the event’s location, the app will provide you with a list of required documents",
    "You can send the files to the commissioners for review",
    "The status of all the documents can be tracked directly through the app"
  ]

  const options = [
    { name: "Registered", value: "782", profiles: true },
    { name: "New this month", value: "34" },
    // { name: "Connections made", value: "302" },
  ];

  // const dataFighters = [
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Elena Solano', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'John Tompson', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' }
  // ]

  const dataFighters = [
    { img: 'https://fightscout.s3.amazonaws.com/uploads/2_zyPD0kO1dGxH9sMpZ9k.jpg', title: 'Meaghan Penning', nationality: 'US', flag: 'flag-US.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2Fiy4cgL-u0Ys_bTZqQZ5wg.jpg', title: 'Narges Mohseni', nationality: 'AT', flag: 'flag-AT.png', zoom: true, zoomRight: true },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FfsBmc8QIVjCvKKFy2smJS.jpg', title: 'Eman Faisal Khan', nationality: 'PK', flag: 'flag-PK.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FOwNd9Mf1aLaU7mWPhmrpU.jpg', title: 'Alphonse Viny Bessala', nationality: 'AE', flag: 'flag-AE.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/SxjlWzcdfQPtBmdnJlWWn.jpg', title: 'Neagu Stefania', nationality: 'RO', flag: 'flag-RO.png', zoom: true },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FT3askX2yge8nKtbCz0KWT.jpg', title: 'Maisha Maria Katz', nationality: 'TH', flag: 'flag-TH.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/a5br17fpL9RZMrfw3ckNI.jpg', title: 'Robson', nationality: 'BR', flag: 'flag-BR.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/FMUYTApvPaRCSKKR4pIlN.jpg', title: 'Emanuil Bogoev', nationality: 'BG', flag: 'flag-BG.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/hIbdnsOw44PNXTRayKpCb.jpg', title: 'Roberto Ionescu', nationality: 'ES', flag: 'flag-ES.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/xFCzCJmLAwSx8sotAR_Eq.jpg', title: 'Ramadan Esmaeel', nationality: 'EG', flag: 'flag-EG.png' },
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="profile-container">
      {isMobileVersion === 1 ? (
        <>
          <Section
            video={"/fighter.mp4"}
            title="CREATE YOUR PROFILE"
            noAlign={1}
            invert
            mobileTitle
            mobile={isMobileVersion}
            profile={"mobile-fighter"}
            height={`fit-content`}
            // height={window.innerHeight < 690 ? 210 : 165}
            renderContent={() => {
              return (
                <>
                  <div className="line-and-text mb-3 padding h-fit">
                    <h3 className="lower-title fighters">
                      LET THE FIGHTING WORLD SEE WHO YOU ARE
                    </h3>
                    {/* <br /> */}
                    <p style={{ marginTop: "-5%" }}><b>Fight Scout</b> is the first app that allows you to create and manage a professional profile that makes you stand out and build the ultimate career. Add crucial information to your profile that makes you stand out:</p>
                  </div>

                  <div className="line-and-tabs padding">
                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Fighting styles</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Weigh category</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Height</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Nationality</p>
                  </div>
                  
                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Current location</p>
                  </div>
                  
                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Fight record and links</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Professional photos</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">Connect with your manager</p>
                  </div>

                  <div className="box-wrapper w-full text-center">
                    <div className="leftt-line" />
                    <p className="tab-text smaller">List your couch</p>
                  </div>
                </div>
                  <br />
                </>
              );
            }}
          />
        </>
      ) : (
        <Section
          video={"/fighter.mp4"}
          halfVideo
          title="CREATE YOUR PROFILE"
          invert
          blended
          heightStretch={true}
          profile={"fighter"}
          // fixedHeight
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
                  <br />
                  <p style={{ fontSize: "18px "}}>
                  <b>Fight Scout</b> is the first app that allows you to create and manage a professional profile that makes you stand out and build the ultimate career. Add crucial information to your profile that makes you stand out:
                  </p>
                </div>
                <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8">
                  <div className="box-wrapper">
                    <p className="tab-text smaller">Fighting styles</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Weigh category</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Height</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Nationality</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Current location</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Fight record and links</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Professional photos</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Connect with your manager</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">List your couch</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      )}

  {isMobileVersion === 1 ? 
   <>
    <SliderComponent
   title="FRESH FROM THE APP"
   subtitle="Say hello to this  month’s new faces"
   profile
   smallSlides={true}
   data={dataFighters}
  />
   </> :  
   <SliderComponent
   title="FRESH FROM THE APP"
   subtitle="Say hello to this  month’s new faces"
   profile
   smallSlides={true}
   data={dataFighters}
  />}

      {isMobileVersion === 1 ? (
        <>
          <TitleComponent
            title={"FIND NEW OPPORTUNITIES"}
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h2 style={{ fontSize: "18px", marginLeft: "5%",marginTop:"0%" }}>
            APPLY TO FIGHT ON EVENTS AND CONNECT WITH PROMOTERS
          </h2>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/new-opportunities-phone.png"
            reversed
            mobile
            title={""}
            renderContent={() => {
              return (
                <NumberList
                data={opportunitiesData}
                 />
              );
            }}
          />
        </>
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/new-opportunities-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/new-opportunities-background.png")`}
          title="FIND NEW OPPORTUNITIES"
          reversed
          center
          profile
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">
                    APPLY TO FIGHT ON EVENTS AND CONNECT WITH PROMOTERS
                  </h3>
                  <br />

                  <NumberList
                  data={opportunitiesData}
                  profile
                   />
                </div>
              </>
            );
          }}
        />
      )}

      <StatisticsComponent
        title="FIGHTERS IN THE APP"
        subtitle="This is what our users are saying"
        options={options}
        // height={100}
        profile
        profileType='fighter'
        noMargin
       />

      {isMobileVersion === 1 ? (
        <>
          <TitleComponent
            title={"MEDICAL RECORDS AND DOCUMENTS"}
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h2 className="uppercase" style={{ fontSize: "22px", marginLeft: "5%",marginTop:"0%" }}>
            Get your documents for a fight reviewed through the app
          </h2>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-phone.png"
            // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-background.png")`}
            reversed
            title={""}
            mobile
            renderContent={() => {
              return (
                  <NumberList
                  data={documentsData}
                  learnMore='/' 
                   />
              );
            }}
          />
        </>
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-background.png")`}
          title="MEDICAL RECORDS AND DOCUMENTS"
          reversed
          center
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title uppercase">
                  Get your documents for a fight reviewed through the app
                  </h3>
                  <br />

                  <NumberList
                  data={documentsData}
                  learnMore='/innovations' 
                  scrollRef='medical records and documents'
                   />
                </div>
              </>
            );
          }}
        />
      )}

       

      <div className="tabs-component-container">
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
              <h2 className="upper-mobile-title" style={{ marginLeft: "5%" }}>
                UNIQUE FUNCTIONS
              </h2>
              <h5 className="inner-mobile-title uppercase" style={{ marginLeft: "5%" }}>
                Find what you need with Fight Scout
              </h5>
              <div className="background-tab">Explore tab</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-explore-tab.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      As a fighter, you are constantly on the look out for new opportunities. When you click the “Explore” tab, you will be able to search for just about anything. You can find out if there are upcoming events which are currently recruiting or browse for a new connection, whether is with a promoter, manager or a coach - the explore tab has got you covered.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Smart profile</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-smart-profile.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                        Do you want to stand out? Then the Fight Scout app is for you. You can build your profile as much as you desire. Tell the fight world all about yourself. List all the sports you practice now or used to practice with your achievements. Set up a cool gallery, add social media links and connect with other people. You can add daily activities for higher engagement with your profile.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Manager</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-manager.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                    <>
                    <p className="normal-tab-text mb-3">
                    If you are looking for a manager, use <b>Fight Scout</b> smart search function to find a suitable one based on criteria of your choice. You can also chat with them, before sending a request to connect. 
                    </p>
                    <p className="normal-tab-text">If you already have a manager, then simply invite them to download <b>Fight Scout</b> by sharing the app and let them manage your career from their profile. You can always connect or disconnect with the managers from your profile settings and remember, <b>Fight Scout</b> doesn't charge any commissions on your fights or contracts.  </p>
                  </>
                  );
                }}
              />
              <div className="background-tab">Coach</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-coach.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                  <>
                    <p className="normal-tab-text mb-3">
                    If you are looking for a coach, use <b>Fight Scout</b>  smart search function to find a suitable one based on criteria of your choice. You can also see their achievements, experience and certificates or event chat, before sending them a request to connect. 
                    </p>
                    <p className="normal-tab-text">If you already have a coach, then simply invite them to download <b>Fight Scout</b> by sharing the app.</p>
                  </>
                  );
                }}
              />
              <div className="background-tab">Chat</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-chat.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                        <b>Fight Scout</b> chat function will help you connect, negotiate and confirm your fight offers directly with the promoters or managers of your choice. If your profile has all the information they need to sign with you, the only thing left to discuss is the contract details, and once you are happy with the offer, you can close the deal using the chat by sharing files, voice messages and even schedule a video call directly in the app.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Status</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-status.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                    <p className="normal-tab-text">
                      You can set your profile status to <b>Active</b> when you are ready to accept fight offers, or you can choose the <b>“Rest"</b> function if you want to recover or take a break from fighting. This will help promoters and managers in deciding whether to invite you for their events. 
                    </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"UNIQUE FUNCTIONS"} />
                <h3 className="lower-title uppercase" style={{marginLeft:"5%"}}>
                  Find what you need with Fight Scout
                </h3>
                <Tabs
                  tabs={["Explore tab", "Smart profile", "Manager", "Coach", "Chat", "Status"]}
                  selected={selectedTab}
                  onSelect={(value) => setSelectedTab(value)}
                />
                {selectedTab === "Explore tab" ? (
                  <>
                    <p className="normal-tab-text">
                    As a fighter, you are constantly on the look out for new opportunities. When you click the “Explore” tab, you will be able to search for just about anything. You can find out if there are upcoming events which are currently recruiting or browse for a new connection, whether is with a promoter, manager or a coach - the explore tab has got you covered.
                    </p>
                  </>
                ) : selectedTab === "Smart profile" ? (
                  <>
                    <p className="normal-tab-text">
                    Do you want to stand out? Then the <b>Fight Scout</b> app is for you. You can build your profile as much as you desire. Tell the fight world all about yourself. List all the sports you practice now or used to practice with your achievements. Set up a cool gallery, add social media links and connect with other people. You can add daily activities for higher engagement with your profile.
                    </p>
                  </>
                ) : selectedTab === "Manager" ? (
                  <>
                    <p className="normal-tab-text">
                    If you are looking for a manager, use <b>Fight Scout</b> smart search function to find a suitable one based on criteria of your choice. You can also chat with them, before sending a request to connect. 
                    </p>
                    <br />
                    <p className="normal-tab-text">If you already have a manager, then simply invite them to download <b>Fight Scout</b> by sharing the app and let them manage your career from their profile. You can always connect or disconnect with the managers from your profile settings and remember, <b>Fight Scout</b> doesn't charge any commissions on your fights or contracts.  </p>
                  </>
                ) : selectedTab === "Coach" ? (
                  <>
                    <p className="normal-tab-text">
                    If you are looking for a coach, use <b>Fight Scout</b>  smart search function to find a suitable one based on criteria of your choice. You can also see their achievements, experience and certificates or event chat, before sending them a request to connect. 
                    </p>
                    <br />
                    <p className="normal-tab-text">If you already have a coach, then simply invite them to download <b>Fight Scout</b> by sharing the app.</p>
                  </>
                ) : selectedTab === "Chat" ? (
                  <>
                    <p className="normal-tab-text">
                    <b>Fight Scout</b> chat function will help you connect, negotiate and confirm your fight offers directly with the promoters or managers of your choice. If your profile has all the information they need to sign with you, the only thing left to discuss is the contract details, and once you are happy with the offer, you can close the deal using the chat by sharing files, voice messages and even schedule a video call directly in the app.
                    </p>
                  </>
                ) : selectedTab === "Status" ? (
                  <>
                    <p className="normal-tab-text">
                    You can set your profile status to <b>Active</b> when you are ready to accept fight offers, or you can choose the <b>“Rest"</b> function if you want to recover or take a break from fighting. This will help promoters and managers in deciding whether to invite you for their events. 
                    </p>
                  </>
                ) : (
                  <>
                    <p className="normal-tab-text">
                    As a fighter, you are constantly on the look out for new opportunities. When you click the “Explore” tab, you will be able to search for just about anything. You can find out if there are upcoming events which are currently recruiting or browse for a new connection, whether is with a promoter, manager or a coach - the explore tab has got you covered.
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-unique-functions-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={
                selectedTab === "Explore tab"
                  ? pictureMap["Step 1"]
                  : selectedTab === "Smart profile"
                  ? pictureMap["Step 2"]
                  : selectedTab === "Manager"
                  ? pictureMap["Step 3"]
                  : selectedTab === "Coach"
                  ? pictureMap["Step 4"]
                  : selectedTab === "Chat"
                  ? pictureMap["Step 5"]
                  : selectedTab === "Status" && pictureMap["Step 6"]
              }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Fighter;
