import React, { useState, useEffect } from "react";
import { Section, TitleComponent, Tabs, SliderComponent, NumberList, StatisticsComponent } from "../../components";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { setRef } from "../../actions";
import { history } from "../../config/stores";

const pictureMap = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-follow.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-interests.png",
  "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-share.png",
};

const Fan = () => {
  const [selectedTab, setSelectedTab] = useState("Follow");
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const dispatch = useDispatch();

  const discoverData = [
    "Discover events through the app or via smart search",
    "Want to watch it at the arena? Discover the buy tickets link",
    "Want to watch it online? Discover the schedule and Watch directly from the app",
    "Missed an event? Come back later and watch the saved videos"
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="profile-container">
      {isMobileVersion === 1 ? (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-explore-phone.png"
          title="EXPLORE THE FIGHT WORLD"
          invert
          mobileTitle
          mobile={isMobileVersion}
          height={`fit-content`}
          profile={"mobile-fan"}
          renderContent={() => {
            return (
              <>
                <div className="line-and-text mb-3 padding">
                  
                  <h3 className="lower-title mt-3">
                    GLOBAL INFORMATION FROM ALL ASPECTS OF THE COMBAT SPORTS
                    INDUSTRY
                  </h3>
                  {/* <br /> */}

                  <p style={{ fontSize: "18px"}}>
                  Fight Fans deserve the luxury of finding all the information they want in a simple and focused way. <b>Fight Scout</b> has an arsenal of functionalities giving you the tools you need:
                  </p>
                </div>

                <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8 padding">
                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Smart search</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Latest activities</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Watch events live from the app</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Follow your favourite accounts</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Switch to a profesional profile</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-explore-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-explore-background.png")`}
          title="EXPLORE THE FIGHT WORLD"
          invert
          blended
          profile={"fan"}
          fixedHeight
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">
                    GLOBAL INFORMATION FROM ALL ASPECTS OF THE COMBAT SPORTS
                    INDUSTRY
                  </h3>
                  <br />
                  <p style={{ fontSize: "18px"}}>
                  Fight Fans deserve the luxury of finding all the information they want in a simple and focused way. <b>Fight Scout</b> has an arsenal of functionalities giving you the tools you need:
                  </p>
                </div>

                <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8">
                  <div className="box-wrapper">
                    <p className="tab-text smaller">Smart search</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Latest activities</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Watch events live from the app</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Follow your favourite accounts</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Switch to a profesional profile</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      )}

      {isMobileVersion === 1 ? (
        <>
          <TitleComponent
            title={"DISCOVER AND WATCH EVENTS LIVE"}
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h3 className="lower-title uppercase" style={{ fontSize: "18px", marginLeft: "5%" }}>
            ONE APP FOR THE ENTIRE COMBAT SPORTS COMMUNITY
          </h3>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-discover-phone.png"
            reversed
            title={""}
            renderContent={() => {
              return (
                <NumberList
                data={discoverData}
                learnMore='/innovations'
                scrollRef='stream and watch events'
                 />
              );
            }}
          />
        </>
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-discover-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-discover-background.png")`}
          title="DISCOVER AND WATCH EVENTS LIVE"
          reversed
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">ONE APP FOR THE ENTIRE COMBAT SPORTS COMMUNITY</h3>
                  <br />
                  <NumberList
                  data={discoverData}
                  learnMore='/innovations'
                  scrollRef='stream and watch events'
                   />
                </div>
              </>
            );
          }}
        />
      )}

      <div className="tabs-component-container">
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
              <h2 className="upper-mobile-title" style={{ marginLeft: "5%" }}>
                GET THE MOST OUT OF FIGHT SCOUT
              </h2>
              <h5 className="inner-mobile-title" style={{ marginLeft: "5%" }}>
                HAVE THE BEST EXPERIENCE YET
              </h5>
              <div className="background-tab">Follow</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-follow.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      You can follow your favourite fighters, promoters or managers and receive updates on their activities. Whether there is a new event registered in your area, or a fighter you follow has just signed a deal to fight, its all right here for you. <b>Fight Scout</b> allows fans to experience something innovative and helpful.
                      </p>
                  );
                }}
              />
              <div className="background-tab">The best content</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-interests.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      We’ve created the “Interests” functionality for the fans. Every user registered as a fan can select the combat sports they like the most. This way we can show them the best content and notify them for new events or trending fighters.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Share</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-share.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      As a fan, you can share your interests from Fight Scout with your friends. You can send them a link from the app or share your desired combat sports content in all other social media platforms as a story or a post by using the custom sharing option. <b>Fight Scout</b> is the platform that has it all.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent
                  title={"GET THE MOST OUT OF FIGHT SCOUT"}
                  marginleft={4}
                />
                <h3 className="lower-title" style={{marginLeft:"5%"}} >HAVE THE BEST EXPERIENCE YET</h3>
                <Tabs
                  tabs={[
                    "Follow",
                    "The best content",
                    "Share",
                  ]}
                  selected={selectedTab}
                  onSelect={(value) => setSelectedTab(value)}
                />
                {selectedTab === "Follow" ? (
                  <>
                    <p className="normal-tab-text">
                    You can follow your favourite fighters, promoters or managers and receive updates on their activities. Whether there is a new event registered in your area, or a fighter you follow has just signed a deal to fight, its all right here for you. <b>Fight Scout</b> allows fans to experience something innovative and helpful.
                    </p>
                  </>
                ) : selectedTab === "Share" ? (
                  <>
                    <p className="normal-tab-text">
                    As a fan, you can share your interests from Fight Scout with your friends. You can send them a link from the app or share your desired combat sports content in all other social media platforms as a story or a post by using the custom sharing option. <b>Fight Scout</b> is the platform that has it all.
                    </p>
                    <h4 className="red-link text-left underline uppercase text-red text-md fontFamily-light cursor-pointer mt-5"
                    onClick={() => {
                      dispatch(setRef('custom share'))
                      history.push('/innovations')
                      }}>Learn more about this functionality</h4>
                  </>
                ) : selectedTab === "The best content" ? (
                  <>
                    <p className="normal-tab-text">
                    We’ve created the “Interests” functionality for the fans. Every user registered as a fan can select the combat sports they like the most. This way we can show them the best content and notify them for new events or trending fighters.
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fan-get-the-most-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={selectedTab === "Follow"
                    ? pictureMap["Step 1"]
                    : selectedTab === "The best content"
                    ? pictureMap["Step 2"]
                    : selectedTab === "Share"
                    ? pictureMap["Step 3"]
                    : ""} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Fan;
