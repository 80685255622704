import React from "react";
import "./styles.scss";
import { history } from "../../config/stores";
import { useLocation } from "react-router-dom";
import { Button } from "../../components";
import Terms from "../../assets/files/Terms.pdf";
import Privacy from "../../assets/files/digidrive_privacy.pdf";
import ERDF from "../../assets/images/ERDF.jpg";
import FOF from "../../assets/images/FOF.png";
import OPIC from "../../assets/images/OPIC.jpg";

const Footer = () => {
  const location = useLocation().pathname;
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  return (
    <>
      {isMobileVersion === 1 ? (
        <>
          <div className="mobile-full-footer-container">
            <div className="mobile-footer-element">
              <div className="mobile-footer-inner-element">
                <p>DOWNLOAD THE APP FROM</p>
                <div className="outer-mobile-flex-container">
                  <div className="mobile-flex-container">
                    <div className="mobile-app-store white" onClick={() => window.open("https://apps.apple.com/bg/app/fightscout/id1590863097?fbclid=IwAR13u_6XIomgZqkcJJuSPiXTrcSVrW94ALkVS2MOMTGHJl9ohpIIVpsKQ2U")} />
                    <div className="mobile-google-play white" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.socourt.combater")} />
                  </div>
                  <div className="mobile-qr" />
                </div>
              </div>
            </div>
            <div className="mobile-footer-element">
              <div className="footer-inner-element contact" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <p id="mobile-bigger-info" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => history.push("/")}>
                  <b>HOME</b>
                </p>
                <p id="mobile-bigger-info" style={{ fontSize: "18px" }}>
                  <b>PROFILES</b>
                </p>
                <p className="regular" style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "0px" }} onClick={() => history.push("/fighter")}>
                  FIGHTER
                </p>
                <p className="regular" style={{ fontSize: "16x", cursor: "pointer", paddingLeft: "0px" }} onClick={() => history.push("/coach")}>
                  COACH
                </p>
                <p className="regular" style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "0px" }} onClick={() => history.push("/promoter")}>
                  PROMOTER
                </p>
                <p className="regular" style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "0px" }} onClick={() => history.push("/manager")}>
                  MANAGER
                </p>
                <p className="regular" style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "0px" }} onClick={() => history.push("/fan")}>
                  FAN
                </p>
                <p id="mobile-bigger-info" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => history.push("/innovations")}>
                  <b>INNOVATIONS</b>
                </p>
                <p id="mobile-bigger-info" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => history.push("/how")}>
                  <b>HOW IT WORKS</b>
                </p>
                <p id="mobile-bigger-info" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => history.push("/about")}>
                  <b>ABOUT US</b>
                </p>
                <p id="mobile-bigger-info" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => history.push("/blog")}>
                  <b>BLOG</b>
                </p>
              </div>
            </div>
            <div className="mobile-footer-element">
              <p id="mobile-bigger-regular-info">CONTACT US</p>
              <div className="mobile-contact-container">
                <p id="mobile-contact-info">
                  <b style={{ color: "#E5214E" }}>E:</b> team@fightscout.app
                </p>
              </div>
            </div>
            <div className="mobile-footer-element">
              <p id="mobile-bigger-regular-info">FOLLOW US</p>
              <div className="mobile-all-logos">
                <div className="mobile-footer-social-media-logo facebook" onClick={() => window.open("https://m.facebook.com/Fight-Scout-app-109503108323167/")} />
                <div className="mobile-footer-social-media-logo twitter" onClick={() => window.open("https://twitter.com/fightscoutapp?lang=bg")} />
                <div className="mobile-footer-social-media-logo instagram" onClick={() => window.open("https://www.instagram.com/fightscoutapp")} />
                <div className="mobile-footer-social-media-logo linkedin" onClick={() => window.open("https://www.linkedin.com/company/fight-scout-app")} />
                <div className="mobile-footer-social-media-logo youtube" onClick={() => window.open("https://www.youtube.com/channel/UCHN-coU9cd-Qv9xm9ePYATQ")} />
              </div>
              <br />
            </div>
            <div className="mobile-footer-element last !border-b-0">
              <p id="bigger" className="mb-2" onClick={() => window.open(Privacy)}>
                PRIVACY POLICY
              </p>
              <p id="bigger" onClick={() => window.open(Terms)}>
                TERMS AND CONDITIONS
              </p>
            </div>
            <div className={`w-full flex !flex-col items-center px-5 gap-5`}>
              <div className="h-[100px] w-full bg-contain bg-no-repeat bg-center ml-5" style={{ backgroundImage: `url(${ERDF})` }} />
              <div className="h-[120px] w-full bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${FOF})` }} />
              <div className="h-[120px] w-full bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${OPIC})` }} />
              <p className="w-full !font-light pb-3">Fight Scout has been supported by "Vitosha Venture Partners Fund l", a private equity fund, co-financed by the European Structural and Investment Funds under the operational program “Innovation and Competitiveness 2014-2020”, managed by the Fund Manager of Financial Instruments in Bulgaria.</p>
            </div>
          </div>
        </>
      ) : (
        <div className={`full-footer-container ${location === "/" && "home"} ${location === "/charging" && "home"} ${location === "/clients" && "home"} ${location === "/app" && "home"}`}>
          <div className="upper-footer-container">
            <div
              className="footer-element column"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="footer-inner-element" style={{ marginTop: "8%", marginLeft: "10%" }}>
                <p className="mb-4" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  <b>DOWNLOAD THE APP FROM</b>
                </p>
                <div className="flex-container">
                  <div className="left-flex-container">
                    <div className="app-store white" onClick={() => window.open("https://apps.apple.com/bg/app/fightscout/id1590863097?fbclid=IwAR13u_6XIomgZqkcJJuSPiXTrcSVrW94ALkVS2MOMTGHJl9ohpIIVpsKQ2U")} />
                    <div className="google-play white" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.socourt.combater")} />
                  </div>
                  <div className="right-flex-container">
                    <div className="qr-code"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-element" style={{ alignItems: "center" }}>
              <div className="footer-inner-element" style={{ display: "flex", width: "90%", marginLeft: "20%" }}>
                <div
                  style={{
                    width: "50%",
                    marginLeft: "4%",
                    display: "flex",
                    gap: "7px",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <p id="bigger-info semibold" style={{ fontSize: "15px", cursor: "pointer", fontWeight: "bold", lineHeight: "0.9em", margin: "4px 0px" }} onClick={() => history.push("/")}>
                    HOME
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "15px", cursor: "pointer", fontWeight: "bold", lineHeight: "0.9em", margin: "4px 0px" }} onClick={() => history.push("./how")}>
                    HOW IT WORKS
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "15px", cursor: "pointer", fontWeight: "bold", lineHeight: "0.9em", margin: "4px 0px" }} onClick={() => history.push("/about")}>
                    ABOUT US
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "15px", cursor: "pointer", fontWeight: "bold", lineHeight: "0.9em", margin: "4px 0px" }} onClick={() => history.push("/innovations")}>
                    INNOVATIONS
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "15px", cursor: "pointer", fontWeight: "bold", lineHeight: "0.9em", margin: "4px 0px" }} onClick={() => history.push("/blog")}>
                    BLOG
                  </p>
                </div>
                <div style={{ width: "50%", marginTop: "5%" }}>
                  <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>PROFILES</h3>
                  <p id="bigger-info semibold" style={{ fontSize: "14px", cursor: "pointer", margin: "5px 0px" }} onClick={() => history.push("/fighter")}>
                    FIGTHER
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "14px", cursor: "pointer", margin: "5px 0px" }} onClick={() => history.push("./coach")}>
                    COACH
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "14px", cursor: "pointer", margin: "5px 0px" }} onClick={() => history.push("./promoter")}>
                    PROMOTER
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "14px", cursor: "pointer", margin: "5px 0px" }} onClick={() => history.push("/manager")}>
                    MANAGER
                  </p>
                  <p id="bigger-info semibold" style={{ fontSize: "14px", cursor: "pointer", margin: "5px 0px" }} onClick={() => history.push("/fan")}>
                    FAN
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-element">
              <div className="footer-inner-element contact" style={{ display: "flex", width: "95%", marginLeft: "13%" }}>
                <div style={{ width: "50%" }}>
                  <p id="bigger-info" style={{ marginLeft: "8%", marginBottom: "10%", fontSize: "14px", fontWeight: "bold" }}>
                    <b>CONTACT US</b>
                  </p>
                  <div className="contact-container">
                    <p id="contact-info" style={{ height: "15%", fontSize: "14px" }} href="mailto:team@fidweb.net">
                      <b style={{ color: "#E5214E" }}>E:</b> team@fightscout.app
                    </p>
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <p id="bigger-info" style={{ marginBottom: "10%", fontSize: "14px", fontWeight: "bold" }}>
                    <b>FOLLOW US</b>
                  </p>
                  <div className="footer-social-media-logo facebook" onClick={() => window.open("https://facebook.com/Fight-Scout-app-109503108323167/")} />
                  <div className="footer-social-media-logo instagram" onClick={() => window.open("https://www.instagram.com/fightscoutapp/")} />
                  <div className="footer-social-media-logo linkedin" onClick={() => window.open("https://www.linkedin.com/company/fight-scout-app")} />
                  <div className="footer-social-media-logo youtube" onClick={() => window.open("https://www.youtube.com/channel/UCHN-coU9cd-Qv9xm9ePYATQ")} />
                </div>
              </div>
            </div>
          </div>
          <div className="lower-footer-container !flex-col !w-full !items-center !mt-2">
            <div
              className="lower-footer-element py-4"
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <p id="bigger" style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => window.open(Privacy)}>
                PRIVACY POLICY
              </p>
              <p id="bigger" style={{ marginLeft: "10%", cursor: "pointer", fontSize: "14px" }} onClick={() => window.open(Terms)}>
                TERMS AND CONDITIONS
              </p>
            </div>
            <div className={`w-full ${window.innerWidth < 1100 ? "h-[180px]" : "h-[145px]"} flex items-center px-5 gap-5`}>
              <div className="h-[125px] w-[125px] bg-contain bg-no-repeat bg-center ml-5" style={{ backgroundImage: `url(${ERDF})` }} />
              <div className="h-[125px] w-[260px] bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${FOF})` }} />
              <div className="h-[125px] w-[260px] bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${OPIC})` }} />
              <p className="w-[calc(100%_-_700px)] !text-sm !font-light">
                Fight Scout has been supported by "Vitosha Venture Partners Fund l", a private equity fund, co-financed by the European Structural and Investment Funds under the operational program “Innovation and Competitiveness 2014-2020”, managed by the Fund Manager of Financial Instruments in Bulgaria.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
