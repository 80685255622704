import React, { Component } from "react";
import { Provider } from "react-redux";
import { configureStore } from "./config/stores";
import { Router } from "react-router-dom";
import { history } from "./config/stores";
import Routes from "./components/routes/Routes";
import "./App.scss";

let store = configureStore();

class App extends Component {
  componentDidMount() {
    document.body.style.zoom = '100%';
  }

  render()
   {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
