import React, { useState, useEffect } from "react";
import { Section, TitleComponent, Tabs, SliderComponent, NumberList, StatisticsComponent } from "../../components";
import "./styles.scss";

const pictureMap = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Coach-explore-tab.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-coach.png",
  "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-chat.png",
};

const Coach = () => {
  const [selectedTab, setSelectedTab] = useState("Explore tab");
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  const options = [
    { name: "Registered", value: "782", profiles: true },
    { name: "New profiles this month", value: "34" },
    // { name: "Connections made", value: "302" },
  ];

  const dataCoaches = [
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FrMywdsNFcF7FJScZzsgvM.jpg', title: 'Fabio Moraes do...', nationality: 'BR', flag: 'flag-BR.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FkK24VPIgxt-igOzLDM0Cc.jpg', title: 'Trainer Gae', nationality: 'TH', flag: 'flag-TH.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FRUxk9Fbhcrp7U2XStMx-t.jpg', title: 'Antoan Parchev', nationality: 'BG', flag: 'flag-BG.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/_QsiiUt0SumNFr6t1a0i2.jpg', title: 'Fotios Chatzidialekts', nationality: 'US', flag: 'flag-US.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/OW1Nj6wmT3N8XGTfA0WPz.jpg', title: 'Philipp Schranz', nationality: 'AT', flag: 'flag-AT.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/r_9txz677K9sY9dHYMUw4.jpg', title: 'Iskander Ben Mbarek', nationality: 'TN', flag: 'flag-TN.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FtugiU6ecX9mpOEgCQvf1W.jpg', title: 'Carlos Frota', nationality: 'BR', flag: 'flag-BR.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads%2FM8tSJ8n_1mbeZJbuYNyvJ.jpg', title: 'Edinei Ferreira', nationality: 'US', flag: 'flag-US.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/yrYnfqc31Gj7I8jU3Xa36.jpg', title: 'Rocky Sami Kiblawi', nationality: 'LB', flag: 'flag-LB.png' },
    // { img: 'https://fightscout.s3.amazonaws.com/uploads%2F8B9unqy88wkHkb6JIh9sR.jpg', title: 'Daniel Severo', nationality: 'BR', flag: 'flag-BR.png' },
    { img: 'https://fightscout.s3.amazonaws.com/uploads/HV6OSNNLObC_ST0uhrxAU.jpg', title: 'Benjamin Davis', nationality: 'IE', flag: 'flag-IE.png' },
  ]

  // const dataCoaches = [
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Elena Solano', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'John Tompson', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
  //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' }
  // ]

  const connectionsData = [
    "Discover fighters through the app or via smart search",
    "You can communicate directly through the app",
    "To connect with a fighter you can send an invitation or the fighter can send you one",
    "Once the invitation has been sent, the receiving side must accept it in the app to finish the connection"
  ]

  const workoutsData = [
    "Reach thousands of users by adding your daily activity",
    "You can add it directly from your profile",
    "Each activity can contain photos, videos and text",
    "You can re-share the activity in all other social media Platforms"
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="profile-container">
      {isMobileVersion === 1 ? (
        <>
          <Section
            video={"/coaches.mp4"}
            title="CREATE YOUR PROFILE"
            noAlign={1}
            invert
            mobileTitle
            mobile={isMobileVersion}
            height={`fit-content`}
            profile={"mobile-coach"}
            renderContent={() => {
              return (
                <>
                  <div className="line-and-text mb-3 padding">
                    <h3
                    className="lower-title"
                    >
                      LET THE FIGHTING WORLD SEE WHO YOU ARE
                    </h3>
                    <br />
                    <p style={{ marginTop: "-5%" }}>
                    <b>Fight Scout</b> is the first mobile App enabling you to share your passion as a coach and show the World of Combat Sports who and where you are. Create you personal profile and use your skills, knowledge and experience to really stand out:
                    </p>
                  </div>
                  <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8 padding">
                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Fighting styles</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Based in</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Experience in each style</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Achievements</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Certificates</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Your team or club</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Social media links</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Professional photos</p>
                  </div>

                  <div className="box-wrapper w-full">
                    <p className="tab-text smaller text-center">Fighters you work with</p>
                  </div>
                </div>
                  <br />
                </>
              );
            }}
          />
        </>
      ) : (
        <Section
          video={"/coaches.mp4"}
          halfVideo
          title="CREATE YOUR PROFILE"
          invert
          blended
          // fixedHeight
          heightStretch
          profile={"coach"}
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
                  <br />
                  <p style={{ fontSize: "18px"}}>
                  <b>Fight Scout</b> is the first mobile App enabling you to share your passion as a coach and show the World of Combat Sports who and where you are. Create you personal profile and use your skills, knowledge and experience to really stand out:
                  </p>
                </div>
                <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8">
                  <div className="box-wrapper">
                    <p className="tab-text smaller">Fighting styles</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Based in</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Experience in each style</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Achievements</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Certificates</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Your team or club</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Social media links</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Professional photos</p>
                  </div>

                  <div className="box-wrapper">
                    <p className="tab-text smaller">Fighters you work with</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      )}

    {isMobileVersion === 1 ? 
        <SliderComponent
        title="FRESH FROM THE APP"
        subtitle="Say hello to this  month’s new faces"
        smallSlides={true}
        data={dataCoaches}
        profile
       />
        :  
       <SliderComponent
       title="FRESH FROM THE APP"
       subtitle="Say hello to this  month’s new faces"
       smallSlides={true}
       data={dataCoaches}
       profile
      //  customSLides='coach'
      />}

      {isMobileVersion === 1 ? (
        <>
          <TitleComponent
            title="CONNECT WITH FIGHTERS"
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h3
           className="lower-title uppercase"
            style={{
              fontSize: "18px",
              marginLeft: "5%",
              textTransform: "uppercase",
            }}
          >
            Connect with fighters  and grow together
          </h3>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Connect-phone.png"
            reversed
            title={""}
            mobile
            renderContent={() => {
              return (
                <NumberList
                  data={connectionsData}
                   />
              );
            }}
          />
        </>
      ) : (
        <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Connect-phone.png"
          image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Connect-background.png")`}
          title="CONNECT WITH FIGHTERS"
          reversed
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title" style={{ textTransform: "uppercase" }}>
                  Connect with fighters  and grow together
                  </h3>
                  <br />

                  <NumberList
                  data={connectionsData}
                   />
                </div>
              </>
            );
          }}
        />
      )}

      <StatisticsComponent
        title="COACHES IN THE APP"
        subtitle="This is what our users are saying"
        options={options}
        profile
        profileType='coach'
        height={100}
       />

      {isMobileVersion === 1 ? 
       <>
            <TitleComponent
            title="SHARE YOUR WORKOUTS"
            smallerTitle={1}
            marginleft={5}
            titleMargin={5}
          />
          <h3
            className="lower-title uppercase"
            style={{
              fontSize: "18px",
              marginLeft: "5%",
              textTransform: "uppercase",
              paddingRight: "20px"
            }}
          >
            Add activities and connect with the users
          </h3>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Share-workouts-phone.png"
            reversed
            title={""}
            mobile
            renderContent={() => {
              return (
                <NumberList
                data={workoutsData}
                learnMore='/innovations' 
                scrollRef='activities'
                 />
              );
            }}
          />
       </> :  
       <Section
       mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Share-workouts-phone.png"
       image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Share-workouts-background.png")`}
       title="SHARE YOUR WORKOUTS"
       reversed
       center
       renderContent={() => {
         return (
           <>
             <div className="line-and-text">
               <h3 className="lower-title uppercase">
               Add activities and connect with the users
               </h3>
               <br />

               <NumberList
               data={workoutsData}
               learnMore='/innovations' 
               scrollRef='activities'
                />
             </div>
           </>
         );
       }}
      />}

      <div className="tabs-component-container">
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
              <h2 className="upper-mobile-title" style={{ marginLeft: "5%" }}>
                UNIQUE FUNCTIONS
              </h2>
              <h5
                className="inner-mobile-title"
                style={{ marginLeft: "5%", textTransform: "uppercase" }}
              >
                Find what you need with Fight Scout
              </h5>
              <div className="background-tab">Explore tab</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Coach-explore-tab.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Use the Explore tab to look for new fighters around the world, then use the Smart Search function to fit your specific criteria. <b>Fight Scout</b> wants your business to grow and we are confident the we can contribute greatly to your success.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Smart profile</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-coach.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Control and manage your profile and let everyone in the industry see the information you like them to see. Share your experience, add achievements and certificates. Select the best images and videos, or add the links to your social media channels and websites of your choice and let people know everything about you.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Chat</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-chat.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                        <b>Fight Scout</b> chat provides you with a communication tool that is focused for business only. Having difficulties scrolling through hundreds of emails, or social media chats with people you work with among all the other messages you receive? Choose who can chat with you and communicate only with the people you work with or wish to work with.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"UNIQUE FUNCTIONS"} />
                <h3
                  className="lower-title"
                  style={{ textTransform: "uppercase",marginLeft:"5%" }}
                >
                  Find what you need with Fight Scout
                </h3>
                <Tabs
                  tabs={[
                    "Explore tab",
                    "Smart profile",
                    "Chat",
                  ]}
                  selected={selectedTab}
                  onSelect={(value) => setSelectedTab(value)}
                />
                {selectedTab === "Explore tab" ? (
                  <>
                    <p className="normal-tab-text">
                    Use the Explore tab to look for new fighters around the world, then use the Smart Search function to fit your specific criteria. <b>Fight Scout</b> wants your business to grow and we are confident the we can contribute greatly to your success.
                    </p>
                  </>
                ) : selectedTab === "Smart profile" ? (
                  <>
                    <p className="normal-tab-text">
                    Control and manage your profile and let everyone in the industry see the information you like them to see. Share your experience, add achievements and certificates. Select the best images and videos, or add the links to your social media channels and websites of your choice and let people know everything about you.
                    </p>
                  </>
                ) : (
                  selectedTab === "Chat" && (
                    <>
                      <p className="normal-tab-text">
                        <b>Fight Scout</b> chat provides you with a communication tool that is focused for business only. Having difficulties scrolling through hundreds of emails, or social media chats with people you work with among all the other messages you receive? Choose who can chat with you and communicate only with the people you work with or wish to work with.
                      </p>
                    </>
                  )
                )}
              </div>
            </div>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Coach-unique-functionalities-background.png")`,
              }}
            >
              <img
              className="tab-image"
               src={selectedTab === "Explore tab"
                    ? pictureMap["Step 1"]
                    : selectedTab === "Smart profile"
                    ? pictureMap["Step 2"]
                    : selectedTab === "Chat" && pictureMap["Step 3"]} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Coach;
