import { combineEpics } from 'redux-observable'
import * as starter from './starter'
import * as blog from './blog'
import * as contact from './contact'
import * as general from './general'

export const rootEpic = combineEpics(
  starter.loadingEpic,
  blog.articlesList,blog.articleById,
  contact.sendEmail,
  general.getStatisticsEpic,
  general.getRegisteredUsersEpic,
  general.getProfilesCountEpic,
)
