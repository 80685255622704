import React, { useEffect,useState } from "react";
import { history } from "../../config/stores";
import "./styles.scss";
import { connect } from "react-redux";
import { articleById } from "../../actions";

const Article = ({articleById,currentBlog,allPhotos}) => {

  const [selected,setSelected] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const location_id = window.location.href.split("/")[4];

    articleById(location_id)
  }, []);

  // console.log(currentBlog)
  return (
    <>
      {isMobileVersion === 1 ? (
        <>
          <div className="mobile-article-container">
            <div
              className="mobile-arrow-left"
              onClick={() => history.push("/blog")}
            />
            <h3 style={{marginLeft:"10px"}}>{currentBlog?.title}</h3>
            <div
              className="mobile-right-article-container"
              style={{ backgroundImage: `url(${allPhotos?.at(selected)?.address})` }}
            />
            <div className="slider">
              <div className={`buttonn backwards ${selected === 0 && "inactive"}`} onClick={() => setSelected(selected - 1) }/>
              <div className="slider-counter"><b>{selected + 1}</b> /{allPhotos?.length}</div>
              <div className={`buttonn forwards ${selected === allPhotos?.length - 1 && "inactive"}`} onClick={() => setSelected(selected + 1)}/>
            </div>
            <div className="mobile-neshto">
              <div className="mobile-article-text" dangerouslySetInnerHTML={{ __html: currentBlog?.text }}></div>
            </div>
          </div>
        </>
      ) : (
        <div className="article-container">
          <div className="left-article-container">
            <div
              className="arrow-left"
              onClick={() => history.push("/blog")}
            />
            <h2 style={{marginLeft:"10px"}}>{currentBlog?.title}</h2>
            <div className="neshto">
              <div className="article-text" dangerouslySetInnerHTML={{ __html: currentBlog?.text }}>{}</div>
            </div>
          </div>
          <div
            className="right-article-container"
            style={{ backgroundImage: `url(${allPhotos?.at(selected)?.address})` }}
          >
            <div className="slider">
              <div className={`buttonn backwards ${selected === 0 && "inactive"}`} onClick={() => setSelected(selected - 1) }/>
              <div className="slider-counter"><b>{selected + 1}</b> /{allPhotos?.length}</div>
              <div className={`buttonn forwards ${selected === allPhotos?.length - 1 && "inactive"}`} onClick={() => setSelected(selected + 1)}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentBlog: state.blogs.currentBlog,
  allPhotos:state.blogs.allPhotos
});
const mapDispatchToProps = (dispatch) => ({
  articleById: (payload) => dispatch(articleById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
