export const blogTypes = {
  ARTICLES_LIST: "blogs/ARTICLES_LIST",
  ARTICLES_LIST_SUCCESS: "blogs/ARTICLES_LIST_SUCCESS",
  ARTICLE_BY_ID: "blogs/ARTICLE_BY_ID",
  ARTICLE_BY_ID_SUCCESS: "blogs/ARTICLE_BY_ID_SUCCESS",
};

export const articlesList = (payload) => ({
  type: blogTypes.ARTICLES_LIST,
  payload,
});

export const articleById = (payload) => ({
  type: blogTypes.ARTICLE_BY_ID,
  payload,
});
