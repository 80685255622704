import React from "react";
import { history } from "../../config/stores";
import "./styles.scss";
import moment from "moment";


export default function NewsComponent({ allBlogs }) {
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const filteredBlogs = isMobileVersion ? allBlogs : allBlogs.slice(1);

  return (
    <div className="news-container">
      {filteredBlogs.map((blog, i) => {
        return (
          <div className={`vertical-news ${(i % 2 == 1 || (isMobileVersion && i === 0)) && "line"}`}>
            <div
              className="news-image"
              style={{
                backgroundImage: `url(${blog.coverPhoto.address})`,
              }}
            />
            <div className="text-container news">
              <div className="inner-container">
                <p className="date-news">
                  {moment(blog?.createdAt).format("DD.MM.YYYY")}
                </p>
                <h4 className="title-news" style={{fontSize:"20px",lineHeight:"1.5em"}}>{blog.title}</h4>
                <div className="line-news-title" />
                <div className="read-article">
                  <div
                    className="read-more"
                    onClick={() => {
                      history.push({
                        pathname: "/article",
                        search: `/${blog._id}`,
                      });
                    }}
                  >
                    READ THE ARTICLE
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {filteredBlogs.length % 2 === 1 && <div className="layer"/>}
    </div>
  );
}
