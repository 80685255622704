import React from "react";
import { TitleComponent } from ".././../components";
import { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Lottie from "lottie-react";
import { useInView } from 'react-intersection-observer';

const Section = ({
  renderContent,
  invert,
  title,
  mainImage,
  image,
  animation,
  halfVideo,
  center,
  specialImage,
  selected,
  onSelectImage,
  mobileTitle,
  reversed,
  removeLine,
  titleId,
  contain,
  backgroundBlack,
  smallerImage,
  mobile,
  bigger,
  smaller,
  moveupinner,
  muchsmallerImage,
  blended,
  semibigger,
  muchbigger,
  opacity,
  fullblended,
  profile,
  verymuchbigger,
  height,
  titleMargin,
  fullVideo,
  video,
  noAlign,
  innerMaxHeight,
  fixedHeight,
  contentHeight,
  padding,
  heightStretch
}) => {
  const [ animationData, setAnimationData ] = useState();
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 150
  });

  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }

  const style = {
    height: 200,
    width: 200,
  };
  
  return (
    <div
      // ref={animation ? animationRef : null}
      ref={ref}
      className={`section-container ${heightStretch && "content-fit"} ${invert && "inverted"}  
    ${backgroundBlack && "backgroundBlack"} ${mobile && "mobile"} ${
        bigger && "bigger"
      } 
      ${center && "center"}
    ${semibigger && "semibigger"} ${muchbigger && "muchbigger"} ${
        verymuchbigger && "verymuchbigger"
      } ${opacity && "opacity"} ${fullblended && "fullblended"}`}
      style={{ height: `${height}vh` }}
    >
      {fullVideo ? (
        <>
          <video
            className="video-container"
            autoPlay
            muted
            loop
            webkit-playsinline="true"
            playsInline
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=Wzvah0vE1xE");
            }}
          >
            <source src={"/home.mp4"} type="video/mp4" />
          </video>
        </>
      ) : video?.split("/")[1] === "fighter.mp4" ? (
        <>
          <video
            style={{ cursor: "pointer" }}
            className={`${mobile && "smallerVideo"} ${halfVideo && "half"}`}
            autoPlay
            muted
            loop
            webkit-playsinline="true"
            playsInline
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=wftY3jrZDdk&t=42s");
            }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </>
      ) : video?.split("/")[1] === "home.mp4" ? (
        <>
          <video
            style={{
              cursor: "pointer",
              height: window.innerWidth < 900 ? "20%" : "fit-content",
              objectFit:window.innerWidth < 900 && "cover"
            }}
            autoPlay
            muted
            loop
            webkit-playsinline="true"
            playsInline
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=Wzvah0vE1xE");
            }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </>
      ) : video?.split("/")[1] === "promoter.mp4" ? (
        <>
          <video
            style={{ cursor: "pointer" }}
            className={`${mobile && "smallerVideo"} ${halfVideo && "half"}`}
            autoPlay
            muted
            loop
            // webkit-playsInline
            webkit-playsinline="true"
            playsInline
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=e1rEFlSeKI0");
            }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </>
      ) : video?.split("/")[1] === "coaches.mp4" ? (
        <>
          <video
            style={{ cursor: "pointer" }}
            className={`${mobile && "smallerVideo"} ${halfVideo && "half"}`}
            autoPlay
            muted
            loop
            webkit-playsinline="true"
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
        </>
      ) : (
        <div
          className={`container image relative ${contain && "contain"}  ${
            smallerImage && "smaller"
          } ${muchsmallerImage && "muchsmaller"} ${halfVideo && "half"}`}
          style={{ backgroundImage: image }}
        >
          {mainImage && 
          <div className="main-image-wrapper relative w-full h-full flex items-center justify-center">
            <img className={`main-image h-auto w-full ${mobile ? 'mobile-main-image' : ''}`} src={mainImage} />
          </div>}
          {/* {animation && <Lottie animationData={animation} loop={false} style={style} />} */}
          {(animation && inView) && <Lottie animationData={animation} loop={false} style={style} />}
        </div>
      )}
      <div
        className={`container content ${contentHeight && "content-full"} ${profile && "profile"} ${padding && "padding"} ${color} ${
          moveupinner && "moveupinner"
        } ${fullVideo && "video"} ${
          video?.split("/")[1] === "home.mp4" && "no-align"
        } 
     ${noAlign && "no-align"}
     ${blended && "blended"} ${opacity && "opacity"}
     ${backgroundBlack && "backgroundBlack"}`}
        style={{ maxHeight: `${innerMaxHeight}%` }}
      >
        <div className={`container-inner ${moveupinner && "moveupinner"}`} style={{height:fixedHeight && "80%"}}>
          {profile === "fighter" ? (
            <div className="profile">
              <p>FIGHTER PROFILE</p>
            </div>
          ) : profile === "promoter" ? (
            <div className="profile">
              <p>PROMOTER PROFILE</p>
            </div>
          ) : profile === "manager" ? (
            <div className="profile">
              <p>MANAGER PROFILE</p>
            </div>
          ) : profile === "fan" ? (
            <div className="profile">
              <p>FAN PROFILE</p>
            </div>
          )
           : profile === "all users" ? (
            <div className="profile">
              <p>ALL USERS</p>
            </div>
          )
           : profile === "for fighters" ? (
            <div className="profile">
              <p>FOR FIGHTERS</p>
            </div>
          )
           : profile === "for professionals" ? (
            <div className="profile">
              <p>FOR PROFESSIONALS</p>
            </div>
          )
           : (
            profile === "coach" && (
              <div className="profile">
                <p>COACH PROFILE</p>
              </div>
            )
          )}
          {profile === "mobile-fighter" ? (
            <div className="mobile-profile mt-8 mb-6">
              <p>FIGHTER PROFILE</p>
            </div>
          ) : profile === "mobile-promoter" ? (
            <div className="mobile-profile mt-8 mb-6">
              <p>PROMOTER PROFILE</p>
            </div>
          ) : profile === "mobile-manager" ? (
            <div className="mobile-profile mt-8 mb-6">
              <p>MANAGER PROFILE</p>
            </div>
          ) : profile === "mobile-fan" ? (
            <div className="mobile-profile mt-8 mb-6">
              <p>FAN PROFILE</p>
            </div>
          ) : (
            profile === "mobile-coach" && (
              <div className="mobile-profile mt-8 mb-6">
                <p>COACH PROFILE</p>
              </div>
            )
          )}
          {title === "" ? (
            ""
          ) : (
            <TitleComponent
              title={title}
              removeLine={removeLine}
              titleId={titleId}
              smallerTitle={smaller}
              titleMargin={titleMargin}
              mobile={mobileTitle}
            />
          )}
          {renderContent && renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Section;
