import React from "react";
import { useEffect } from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import {Header,MobileHeader,Footer} from "../../components";
import {Home} from "../../screens/Home";
import {Fighter} from "../../screens/Fighter"
import { Promoter } from "../../screens/Promoter";
import {Manager} from "../../screens/Manager";
import {Fan} from "../../screens/Fan";
import {Coach} from "../../screens/Coach";
import HowItWorks from "../../screens/HowItWorks/HowItWorks";
import About from "../../screens/About/About";
import Article from "../../screens/Article/Article";
import Blog from "../../screens/Blog/Blog";
import Quiz from "../../screens/Quiz/Quiz";
import { Innovations } from "../../screens/Innovations";

const Routes = () => {
  
  useEffect(() => {
    document.body.style.zoom = "100%";
  }, []);

  return (
    <div className="dashboard-wrap">
      <Header />
      <MobileHeader/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/fighter" component={Fighter} />
        <Route exact path="/promoter" component={Promoter} />
        <Route exact path="/manager" component={Manager} />
        <Route exact path="/coach" component={Coach}/>
        <Route exact path="/fan" component={Fan}/>
        <Route exact path="/how" component={HowItWorks}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/article" component={Article}/>
        <Route exact path="/blog" component={Blog}/>
        <Route exact path="/quiz" component={Quiz}/>
        <Route exact path="/innovations" component={Innovations}/>
      </Switch>
      <Footer/>
    </div>
  );
};

export default withRouter(Routes);
