import React, { useState, useEffect } from "react";
import "./styles.scss";
import FAQ from "./info.json";
import { Tabs } from "../../components";
import { ContactComponent } from "../../components/ContactComponent";
import { Icon } from "react-icons-kit";
import { chevronDown } from "react-icons-kit/fa/chevronDown";

const data = FAQ;

const Options = {
  First: "CREATE YOUR ACCOUNT",
  Credits: "FAN ACCOUNT",
  Graphic: "FIGHTER ACCOUNT",
  Management: "PROMOTER ACCOUNT",
  Instructors: "MANAGER ACCOUNT",
  // MPS: "ABOUT THE EVENTS",
  Coach: "COACH ACCOUNT",
  Livestream: "LIVESTREAM FEATURES"
};

const HowItWorks = () => {
  const [selected, setSelected] = useState("CREATE YOUR ACCOUNT");
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // console.log(data)

  return (
    <>
      <div className="Full-container">
        {isMobileVersion === 1 ? (
          <>
            {Object.keys(data).map((key, index) => {
              return (
                // <div className={`question-answer-container ${!data[key][0]?.Question ? 'hidden' : ''}`}>
                <div className={`question-answer-container ${key === "ABOUT THE EVENTS" ? 'hidden' : ''}`}>

                  <div className={`questions-container ${!data[key][0]?.Question ? 'hidden' : ''}`} id={index}>
                    <div className="mobile-question" style={{fontWeight:"bold"}}><b>{key}</b></div>
                  </div>

                  <div className={`questions-container ${!key ? 'hidden' : ''}`} id={index}>
                    <div className="mobile-question" style={{fontWeight:"bold"}}><b>{key}</b></div>
                  </div>

                  {data[key]?.map((quesansw, index) => {
                    return (
                      <>
                        <div
                          className="inner-questions-n-answers"
                          id="question-n-answer"
                        >
                          <ul style={{ marginLeft: "5%" }}>
                            <div
                              className="line-and-text"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {(quesansw?.Question || selected !== "CREATE YOUR ACCOUNT") &&
                              <div
                                className="left-line"
                                style={{
                                  border: "3px solid #E5214E",
                                  borderRadius: "5px",
                                  height: "4vh",
                                  marginRight: "1%",
                                }}
                              />}
                              {quesansw?.Question && 
                              <li className="inner-question" style={{marginLeft:"1%",width:"90%",display:"flex",alignItems:"center"}}>
                                {quesansw.Question}
                              </li>}
                              {/* {quesansw?.Answer1 && 
                              <li className="inner-answer" style={{marginLeft:"1%",width:"90%",display:"flex",alignItems:"center"}}>
                                {quesansw?.Answer1}
                              </li>} */}
                            </div>
                            <div className="inner-asnwer">
                              <p>
                                {data[key][index].Answer1}
                              </p>
                              <p className="mt-5 mb-5">
                                {data[key][index].Answer2}
                              </p>
                              {data[key][index].Answer3 !== "" ? (
                                <p>
                                  {data[key][index].Answer3}
                                </p>
                              ) : (
                                ""
                              )}
                              {quesansw?.Bullets && quesansw?.Bullets?.map((lineText, indexBullet) => {
                                return (
                                  <div className="line-and-text" style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="left-line" style={{ border: "3px solid #E5214E", borderRadius: "5px", height: "4vh", marginRight: "1%" }}/>
                                    <p>{lineText}</p>
                                  </div>
                                )
                              })}
                          {(data[key][index]?.Links !== [] && index === 0) ? 
                            data[key][index]?.Links?.map((link, linkIndex) => {
                              return (
                                <p 
                                className={`uppercase underline text-md text-red cursor-pointer mb-6 ${linkIndex === data[selected][index]?.Links?.length - 1 ? "pb-1" : "pb-6"} ${linkIndex === 0 ? "mt-5" : "mt-0"}`}
                                style={{ marginLeft: "5%", fontFamily: "$font-family-light"}}
                                onClick={() => {
                                  if (link === "How to set up a Fighter account") window.open("https://www.youtube.com/watch?v=wftY3jrZDdk")
                                  else if (link === "How to set up a Promoter account") window.open("https://www.youtube.com/watch?v=MEsjXSah0-s")                                  
                                  else if (link === "How to set up a Manager account") window.open("https://www.youtube.com/watch?v=qATC4yiXCvo")                                  
                                  // else if (link === "How to Create a Coach’s profile") window.open("") -> no video in youtube channel                                  
                                }}>
                                  {link}
                                </p>
                              )
                            })
                           : (
                            ""
                          )}
                            </div>
                          </ul>
                        </div>
                      </>
                    );
                  })}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="Left-container">
              <Tabs
                howitworks
                black
                selected={selected}
                tabs={[
                  Options.First,
                  Options.Credits,
                  Options.Graphic,
                  Options.Management,
                  Options.Instructors,
                  // Options.MPS,
                  Options.Coach,
                  Options.Livestream,
                ]}
                onSelect={(tab) => setSelected(tab)}
              />
            </div>
            <div className="Right-container">
              <div className="Questions">
                <ul className="questions-wrapper">
                  {data[selected].map((select, index) => {
                    return (
                      <li style={{ borderBottom: data[selected].length - 1 > index && "1px solid #E5214E" }}>
                        <div className={`question-and-icon ${data[selected][index].Question ? '' : 'hidden'}`}>
                          <div className="leftt-line" />
                          <div className="question" id="question">
                            {data[selected][index].Question}
                          </div>
                        </div>
                        <div className={`answer`} id="answer1">
                          <p style={{ marginLeft: "5%" }}>
                            {data[selected][index].Answer1}
                          </p>
                          <p style={{ marginLeft: "5%", marginTop: "4%" }}>
                            {data[selected][index].Answer2}
                          </p>
                          {data[selected][index].Answer3 !== "" ? (
                            <p style={{ marginLeft: "5%", padding: selected === "CREATE YOUR ACCOUNT" ? "30px 0px 0px 0px" : "0px" }}>
                              {data[selected][index].Answer3}
                            </p>
                          ) : (
                            ""
                          )}

                          {data[selected][index]?.Bullets && data[selected][index]?.Bullets?.map((bulletText, indexBullet) => {
                            return (
                            <div className={`bullet-and-text ${bulletText ? '' : 'hidden'}`} style={{ marginLeft: "5%" }}>
                              <div className="icon" />
                              <div className="text">
                                {bulletText}
                              </div>
                            </div>
                            )
                          })}

                          {data[selected][index]?.Paragraph ?
                          <p style={{ marginLeft: "5%", marginTop: "4%" }}>
                            {data[selected][index]?.Paragraph}
                          </p> : <></>}

                          {data[selected][index]?.Links !== [] ? 
                            data[selected][index]?.Links?.map((link, linkIndex) => {
                              return (
                                <p 
                                className={`uppercase underline text-md text-red cursor-pointer ${linkIndex === data[selected][index]?.Links?.length - 1 ? "pb-1" : "pb-6"} ${linkIndex === 0 ? "mt-5" : "mt-0"}`}
                                style={{ marginLeft: "5%", fontFamily: "$font-family-light"}}
                                onClick={() => {
                                  if (link === "How to Create a Fighter’s profile") window.open("https://www.youtube.com/watch?v=wftY3jrZDdk")
                                  else if (link === "How to Create a Promoter’s profile") window.open("https://www.youtube.com/watch?v=MEsjXSah0-s")                                  
                                  else if (link === "How to Create a Manager’s profile") window.open("https://www.youtube.com/watch?v=qATC4yiXCvo")                                  
                                  // else if (link === "How to Create a Fan’s profile") window.open("") -> no link                                  
                                  // else if (link === "How to Create a Coach’s profile") window.open("") -> no video in youtube channel                                  
                                }}>
                                  {link}
                                </p>
                              )
                            })
                           : (
                            ""
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
      {isMobileVersion === 1 ? (
        <ContactComponent mobile={1} />
      ) : (
        <ContactComponent />
      )}
    </>
  );
};

export default HowItWorks;
