import React from 'react';
import './styles.scss';
import { history } from '../../config/stores';
import { useDispatch } from "react-redux";
import { setRef } from "../../actions";

const NumberList = ({
    data,
    learnMore,
    scrollRef
}) => {
  const dispatch = useDispatch();
  const navigateOnClick = (link, ref) => {
    history.push(link)
    dispatch(setRef(ref))
  }

  const isMobile = window.innerWidth < 900 ? 1 : 0;

  return (
    <div className={`list-container h-fit ${isMobile ? 'mobile' : ''}`}>
        <div className='container-inner flex-col content-center w-full'>
          {isMobile ?
            <div className='text-outer-wrapper flex flex-col w-full'>
              {data?.map((text, index) => {
                  return (
                      <div className={`row-wrapper flex ${(index === data.length - 1) ? 'last' : ''}`}>
                        <div className='flex-title w-full flex-nowrap'>
                          <h4 className='number'>{index + 1}</h4>
                          <div className='img-wrapper'>
                            <img className='line horizontal' src={require("../../assets/icons/line-horizontal.svg").default} />
                          </div>
                        </div>

                        <p className='text uppercase text-left m-0'>{text}</p>
                      </div>
                  )
              })}
            </div> :
                <div className='text-outer-wrapper flex flex-col w-full'>
                <img className='line' src={require("../../assets/icons/gradient-line.svg").default} />
              {data?.map((text, index) => {
                  return (
                      <div className='row-wrapper flex items-start justify-start gap-3'>
                        <h4 className='number'>{index + 1}</h4> 
                        <p className='text uppercase text-left m-0'>{text}</p>
                      </div>
                  )
              })}
            </div>}

            {learnMore &&
            <h3 
            className='bottom-text underline uppercase text-red cursor-pointer' 
            onClick={() => {
              if (scrollRef) {
                navigateOnClick(learnMore, scrollRef)
              }
              else {
                history.push(learnMore)
              }
            }}>Learn more about this functionality</h3>}
        </div>
    </div>
  )
}

export default NumberList


