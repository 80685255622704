import React, { useState, useEffect } from "react";
import { Base64 } from "js-base64";
import { TitleComponent, Button } from "../../components";
import moment from "moment";
import "./styles.scss";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RadioButton from "../RadioButton/RadioButton";

function ContactComponent({ home, mobile, sendEmail }) {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait a few seconds."
  );
  const [success, setSuccess] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Please wait a few seconds.....") {
          newLoadingMessage = "Please wait a few seconds.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);

  function validateEmail() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  }

  // useEffect(() => {
  //   console.log(checkedValues)
  // }, [checkedValues]);

  return (
    <>
      {mobile === 1 ? (
        <div className={`contact-component-container ${home && "home"}`}>
          <div className="right-container">
            <div className="inner-container">
              <b> <TitleComponent
                title={"REACH OUT"}
                removeLine={1}
                smallerTitle={1}
              /></b>
             
              <p className="contact-subtitle mb-6" style={{ padding: "0px", textAlign: "left", fontSize: "18px" }}>
                YOU HAVE QUESTIONS OR CAN'T FIND THE FEATURE YOU ARE LOOKING
                FOR?
              </p>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={fullName}
                    placeholder={"Your name*"}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={company}
                    placeholder={"Company's name"}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={email}
                    placeholder={"Email*"}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={phone}
                    placeholder={"Phone*"}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-textarea-wrapper">
                  <textarea
                    required
                    value={message}
                    className="form-input"
                    style={{width:"100%"}}
                    placeholder={"Short message"}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col h-full w-full items-start justify-end mt-10'>
                     <RadioButton
                      name="cookie-policy"
                      id="accept1"
                      type='checkbox'
                      value="Agree with our Cookie policy"
                      text="Agree with our Cookie policy"
                      shape="square"
                      onChange={(e) => {
                        let newChecked = checkedValues.slice()
                        if (checkedValues.includes("accept1")) {
                          let indexToRemove = checkedValues.indexOf("accept1")
                          newChecked.splice(indexToRemove, 1)
                        }
                        else {
                          newChecked.push("accept1")
                        }
                        setCheckedValues(newChecked)
                      }}
                      checked={checkedValues.includes("accept1")}
                      />

                     <RadioButton
                      name="privacy-policy"
                      id="accept2"
                      type='checkbox'
                      value="Agree with our Privacy policy"
                      text="Agree with our Privacy policy"
                      shape="square"
                      onChange={(e) => {
                        let newChecked = checkedValues.slice()
                        if (checkedValues.includes("accept2")) {
                          let indexToRemove = checkedValues.indexOf("accept2")
                          newChecked.splice(indexToRemove, 1)
                        }
                        else {
                          newChecked.push("accept2")
                        }
                        setCheckedValues(newChecked)
                      }}
                      checked={checkedValues.includes("accept2")}
                      />
                      </div>
           

              <div
                className="button-container mobilesection"
                style={{marginTop:"10%", fontSize: "18px"}}
                onClick={() => {
                  const firstcheck =
                    document.getElementById("accept1").checked;
                  const secondcheck =
                    document.getElementById("accept2").checked;
                  if (
                    !loading &&
                    validateEmail() &&
                    firstcheck &&
                    secondcheck
                  ) {
                    document.getElementById("error").style.display = "none";
                    setLoading(true);
                    setSuccess(false);
                    const payload = {
                      system: "fightScout",
                      sender: fullName,
                      company: company,
                      email: email,
                      phoneNumber: phone,
                      message: message,
                      onSuccess: () => {
                        setLoading(false);
                        setSuccess(true);
                        setFullName("");
                        setCompany("");
                        setEmail("");
                        setPhone("");
                        setMessage("");
                      },
                      onError: (error) => {
                        setLoading(false);
                        toast(error);
                      },
                    };
                    sendEmail(payload);
                  } else {
                    document.getElementById("error").style.display = "block";
                  }
                }}
              >
                SEND
              </div>
              <p style={{ display: "none" }} id="error">
                Some of the fields do not meet the requierements!
              </p>
              {loading && <p>{loadingMessage}</p>}
              {success && <p>Your asking was sent successfully, thank you!</p>}
            </div>
          </div>
          {/* <div className="left-container" /> */}
        </div>
      ) : (
        <div className={`contact-component-container ${home && "home"}`}>
          <div className="right-container">
            <div className="inner-container">
              <TitleComponent title={"REACH OUT"} removeLine={1} />
              <p style={{ textAlign: "left", marginBottom: "8%" }}>
                YOU HAVE QUESTIONS OR CAN'T FIND THE FEATURE YOU ARE LOOKING
                FOR?
              </p>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={fullName}
                    placeholder={"Your name*"}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={company}
                    placeholder={"Company's name"}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex-container">
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={email}
                    placeholder={"Email*"}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    required
                    className="form-input"
                    type="text"
                    value={phone}
                    placeholder={"Phone number*"}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>

              <div className="flex content-between items-center">
                  <div className="flex-end w-full">
                    <div className="flex-container items-center w-full" style={{width:"100%"}}>
                      <div className="form-textarea-wrapper">
                        <textarea
                          required
                          value={message}
                          style={{width:"100%"}}
                          placeholder={"Short message"}
                          onChange={(event) => setMessage(event.target.value)}
                        />
                      </div>

                      <div className='flex flex-col h-full w-full items-start justify-end'>
                     <RadioButton
                      name="cookie-policy"
                      id="accept1"
                      type='checkbox'
                      value="Agree with our Cookie policy"
                      text="Agree with our Cookie policy"
                      shape="square"
                      onChange={(e) => {
                        let newChecked = checkedValues.slice()
                        if (checkedValues.includes("accept1")) {
                          let indexToRemove = checkedValues.indexOf("accept1")
                          newChecked.splice(indexToRemove, 1)
                        }
                        else {
                          newChecked.push("accept1")
                        }
                        setCheckedValues(newChecked)
                      }}
                      checked={checkedValues.includes("accept1")}
                      />

                     <RadioButton
                      name="privacy-policy"
                      id="accept2"
                      type='checkbox'
                      value="Agree with our Privacy policy"
                      text="Agree with our Privacy policy"
                      shape="square"
                      onChange={(e) => {
                        let newChecked = checkedValues.slice()
                        if (checkedValues.includes("accept2")) {
                          let indexToRemove = checkedValues.indexOf("accept2")
                          newChecked.splice(indexToRemove, 1)
                        }
                        else {
                          newChecked.push("accept2")
                        }
                        setCheckedValues(newChecked)
                      }}
                      checked={checkedValues.includes("accept2")}
                      />
                      </div>
                    </div>
                  </div>
              </div>

              <div className="button-right">
                <div
                  className="button-container larger"
                  onClick={() => {
                    const firstcheck =
                      document.getElementById("accept1").checked;
                    const secondcheck =
                      document.getElementById("accept2").checked;
                    if (
                      !loading &&
                      validateEmail() &&
                      firstcheck &&
                      secondcheck
                    ) {
                      document.getElementById("error").style.display = "none";
                      setLoading(true);
                      setSuccess(false);
                      const payload = {
                        system: "fightScout",
                        sender: fullName,
                        company: company,
                        email: email,
                        phoneNumber: phone,
                        message: message,
                        onSuccess: () => {
                          setLoading(false);
                          setSuccess(true);
                          setFullName("");
                          setCompany("");
                          setEmail("");
                          setPhone("");
                          setMessage("");
                        },
                        onError: (error) => {
                          setLoading(false);
                          toast(error);
                        },
                      };
                      sendEmail(payload);
                    } else {
                      document.getElementById("error").style.display = "block";
                    }
                  }}
                >
                  SEND
                </div>
              </div>
              <p style={{ display: "none" }} id="error">
                You need valid email and agree with our policies!
              </p>
              {loading && <p>{loadingMessage}</p>}
              {success && <p>Your asking was sent successfully, thank you!</p>}
            </div>
          </div>
          <div className="left-container" />
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  allBlogs: state.blogs.allBlogs,
});
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactComponent);
