import React,{useState,useEffect} from "react";
import {Section,TitleComponent,Tabs, SliderComponent, NumberList, StatisticsComponent} from "../../components";
import "./styles.scss";

const pictureMap = {
    "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-explore-tab.png",
    "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-smart-profile.png",
    "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-chat.png"
  };

const Manager = () =>{
    const [selectedTab, setSelectedTab] = useState("Explore tab");
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

    const options = [
      { name: "Registered", value: "782", profiles: true },
      { name: "New profiles this month", value: "34" },
      // { name: "Connections made", value: "302" },
    ];

    const dataManagers = [
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FdCv1jngHrORxti9r6LL5A.jpg', title: 'Chinto Mordillo', nationality: 'ES', flag: 'flag-ES.png', zoomExtra: true },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2Fnp38qeF4Fr3ngkqr6Crlp.jpg', title: 'Masoud Jafari', nationality: 'IR', flag: 'flag-IR.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2F8vd2s9cg8v_-MNzrzfbqe.jpg', title: 'Osmium Sports', nationality: 'AT', flag: 'flag-AT.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FAPj8F8NG95aIkb8WDkvRP.jpg', title: "Eddy m'rad", nationality: 'FR', flag: 'flag-FR.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/q8gZAK9iTbkgdkD2GrRhj.jpg', title: 'Rafael Aronov', nationality: 'IL', flag: 'flag-IL.png', zoomExtra: true, zoomCenter: true },
      { img: 'https://fightscout.s3.amazonaws.com/uploads/hX1l3UWpG9jXhDPW1jkl_.jpg', title: 'XXVI Managem...', nationality: 'PT', flag: 'flag-PT.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2F-YJdaUJCZ3F7VCGcuol2q.jpg', title: 'Laura Abril', nationality: 'US', flag: 'flag-US.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FBzlC9Hbbt5_3Koyb0HMVl.jpg', title: 'Toni Hakala', nationality: 'BR', flag: 'flag-BR.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FlqNFsOQ42T0rx6chqpfN-.jpg', title: 'Hands for Hire', nationality: 'GB', flag: 'flag-GB.png' },
      { img: 'https://fightscout.s3.amazonaws.com/uploads%2FatlgomJsfTKM44T4P_KCT.jpg', title: 'AFL MANAGE...', nationality: 'ES', flag: 'flag-ES.png' },
    ]

    // const dataManagers = [
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Elena Solano', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'John Tompson', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' }
    // ]

    const connectionsData = [
      "Discover fighters through the app or via smart search",
      "You can communicate directly In the app’s chat",
      "To connect with a fighter you can send an invitation or the fighter can send you one",
      "Once the invitation has been sent, the receiving side must accept it in the app to finish the connection"
    ]

    const clientsData = [
      "Discover events through the app or via smart search",
      "Recruiting events will have an opened positions section",
      "If fighters from your management’s list match  the Requirements of a position, you can apply for them",
      "The promoter will review the application and respond in the app"
    ]

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },[])

    return(
    <div className="profile-container">
    {isMobileVersion === 1 ? <Section
      mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-section-1.png"
      title="CREATE YOUR PROFILE"
      invert
      mobileTitle
      mobile={isMobileVersion}
      profile={"mobile-manager"}
      renderContent={()=>{return(<>
                 <div className="line-and-text mb-3 padding">
                    <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
                    <p style={{ fontSize: "18px"}}>
                      <b>Fight Scout</b> is unique and the first mobile App that allows you to create, update and manage an impressive profile accessible anyone in the Combat Sports community seeking a Manager worldwide. Add crucial information that makes you stand out:
                    </p> 
                  </div>  

                  <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8 padding">
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Fighting styles</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Based in</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Agency name</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Official website</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Social media links</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">Professional photos</p>
                   </div>
 
                   <div className="box-wrapper w-full">
                     <p className="tab-text smaller text-center">List managing fighters</p>
                   </div>
                </div>
        
         </>)            
     }}
        /> 
        :
        <Section
             image = {`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-section-1.png")`}
             halfVideo
             title="CREATE YOUR PROFILE"
             invert
             blended
             height={101}
             profile={"manager"}
             fixedHeight
             renderContent={()=>{return(<>
                 <div className="line-and-text">
                    <h3 className="lower-title">LET THE FIGHTING WORLD SEE WHO YOU ARE</h3>
                    <br/>
                    <p style={{ fontSize: "18px"}}>
                      <b>Fight Scout</b> is unique and the first mobile App that allows you to create, update and manage an impressive profile accessible anyone in the Combat Sports community seeking a Manager worldwide. Add crucial information that makes you stand out:
                    </p> 
                  </div>  

                  <div className="line-and-tabs gap-6 items-center justify-start w-full mt-8">
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Fighting styles</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Based in</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Agency name</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Official website</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Social media links</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">Professional photos</p>
                   </div>
 
                   <div className="box-wrapper">
                     <p className="tab-text smaller">List managing fighters</p>
                   </div>
                </div>
               
                </>)            
            }}
        />
    }

    {isMobileVersion === 1 ? 
    <>
      <SliderComponent
       title="FRESH FROM THE APP"
       subtitle="Say hello to this month’s new faces"
       smallSlides={true}
       profile
       data={dataManagers}
      />
    </> :
       <SliderComponent
       title="FRESH FROM THE APP"
       subtitle="Say hello to this month’s new faces"
       smallSlides={true}
       profile
       data={dataManagers}
      />}

    {isMobileVersion === 1 ?  
      <>
      <TitleComponent title={"GROW YOUR CAREER"} smallerTitle={1} marginleft={5}/>
      <h3 style={{fontSize:"18px",marginLeft:"5%"}}>CONNECT WITH FIGHTERS AND GROW TOGETHER</h3>
      <Section
      mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Grow-career-phone.png"
      // image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Grow-career-background.png")`}
      reversed
      title={""}
      mobile
      renderContent={()=>{return(
        <NumberList
        data={connectionsData}
         />
      )
        
      }}
      />
      </>
      :
        <Section
        mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Grow-career-phone.png"
        image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Grow-career-background.png")`}
        title="GROW YOUR CAREER"
        reversed
        profile
        height={100}
        renderContent={()=>{return(<>
                <div className="line-and-text">
                    <h3 className="lower-title">CONNECT WITH FIGHTERS AND GROW TOGETHER</h3>
                    <br/>
                    <NumberList
                    data={connectionsData}
                     />
                </div>  
          </>)
          
        }}
        />
      }

      <StatisticsComponent
        title="MANAGERS IN THE APP"
        subtitle="This is what our users are saying"
        options={options}
        profile
        profileType='manager'
        height={100}
       />

       {isMobileVersion === 1 ?
        <>
           <TitleComponent title={"MANAGE YOUR CLIENTS"} smallerTitle={1} marginleft={5}/>
           <h3 style={{fontSize:"18px",marginLeft:"5%", textTransform: "uppercase" }}>Put your office in your pocket</h3>
           <Section
           mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Manage-clients-phone.png"
           reversed
           title={""}
           mobile
           renderContent={()=>{return(
             <NumberList
             data={clientsData}
              />
           )
             
           }}
           />
        </> :
         <Section
         mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Manage-clients-phone.png"
         image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Manage-clients-background.png")`}
         title="MANAGE YOUR CLIENTS"
         reversed
         height={100}
         renderContent={()=>{return(<>
                 <div className="line-and-text">
                     <h3 className="lower-title uppercase">Put your office in your pocket</h3>
                     <br/>
                     <NumberList
                     data={clientsData}
                      />
                 </div>  
           </>)
           
         }}
         />}

      <div className="tabs-component-container" style={{height: window.innerWidth < 900 ? "100%" : "101vh"}}>
      {window.innerWidth < 900 ? (
        <>
        <div className = "mobile-tabs-container">
        <h2 className="upper-mobile-title" style={{marginLeft:"5%"}}>UNIQUE FUNCTIONS</h2>
        <h5 className="inner-mobile-title" style={{marginLeft:"5%"}}>FIND WHAT YOU NEED WITH FIGHT SCOUT </h5>

        <div className="background-tab">Explore tab</div>
          <Section
              mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Fighter-explore-tab.png"
              mobile={isMobileVersion}
              reversed
              title={""}
              renderContent={()=>{return(
                <p className="normal-tab-text">Use the Explore tab to look for events or fighters from all over the world, 
                and then use the "smart search" function to filter them in what ever order you wish. 
                Your specific needs are what is important for us at <b>Fight Scout</b>, 
                which is why we are confident you will be able to find everything you need to help you grow your business.</p>)
                
              }}
        /> 
      <div className="background-tab">Smart profile</div>
          <Section
              mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-smart-profile.png"
              mobile={isMobileVersion}
              reversed
              title={""}
              renderContent={()=>{return(<>
                <p className="normal-tab-text">Control and manage your profile letting everyone in the industry see the information you want them to see. Use a preferred language, allowing direct communication with people from anywhere in the world. Select your best pictures and videos as well as adding links to your social media channels and websites of your choice, showing people the highlights of your career. <b>Fight Scout</b> gets you the attention you deserve.</p>
                </>)
                
              }}
      />  
      <div className="background-tab">Chat</div>
      <Section
          mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/manager-chat.png"
          mobile={isMobileVersion}
          reversed
          title={""}
          renderContent={()=>{return(<>
            <p className="normal-tab-text"><b>Fight Scout</b> chat provides you with a communication tool that is focused for business only. 
            Having difficulties scrolling through hundreds of emails, 
            or social media chats with people you work with among all the other messages you recieve? 
            Choose who can chat with you and communicate only with the people you work with or wish to work with.</p>
            </>)  
          }}
      /> 

        </div>
        </>
      ) : (
        <>
          <div className="content-container not-blend">
            <div className="text-inner-container">
              <TitleComponent title={"UNIQUE FUNCTIONS"} />
              <h3 className="lower-title" style={{marginLeft:"5%"}}>FIND WHAT YOU NEED WITH FIGHT SCOUT</h3>
              <Tabs
                tabs={["Explore tab", "Smart profile", "Chat"]}
                selected={selectedTab}
                onSelect={(value) => setSelectedTab(value)}
              />
              {selectedTab === "Explore tab" ?
              <>
                <p className="normal-tab-text">Use the Explore tab to look for events or fighters from all over the world, 
                and then use the "smart search" function to filter them in what ever order you wish. 
                Your specific needs are what is important for us at <b>Fight Scout</b>, 
                which is why we are confident you will be able to find everything you need to help you grow your business.
                </p> 
              </> :

              selectedTab === "Smart profile" ?
              <>
              <p className="normal-tab-text">
              Control and manage your profile letting everyone in the industry see the information you want them to see. Use a preferred language, allowing direct communication with people from anywhere in the world. Select your best pictures and videos as well as adding links to your social media channels and websites of your choice, showing people the highlights of your career. <b>Fight Scout</b> gets you the attention you deserve.
              </p>
              </>  :

              selectedTab === "Chat" ? <>
              <p className="normal-tab-text">
              <b>Fight Scout</b> chat provides you with a communication tool which is focused towards business. Scrolling through hundreds of random emails and filtering through social media messages from friends and associates from ones that my contain business opportunities is tedious and time wasting. With <b>Fight Scout</b> chat you choose who can chat with you, meaning you only communicate with people and organisations that benefit you.
              </p>
              </> : ""}
            </div>
          </div>  
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Promoter-unique-functions-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={selectedTab === "Explore tab" ? pictureMap["Step 1"] :
              selectedTab === "Smart profile" ? pictureMap["Step 2"] :
              selectedTab === "Chat" ? pictureMap["Step 3"] : ""} />
            </div>
        </>
      )}
    </div>

    </div>
    );
}

export default Manager;