import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { GeneralTypes } from "../actions";
import { ajax } from "rxjs/ajax";
import { API, basicAuth } from "../config/settings";

export const getStatisticsEpic = (action$) =>
  action$.pipe(
    ofType(GeneralTypes.GET_STATISTICS),
    switchMap(({ payload: { role } }) => {
      return ajax({
        url: `${API}admin/profiles`,
        method: "POST",
        headers: {  
            "Content-Type": "application/json",
            "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmRiMDFhYTMyOTViZTAwMTYyMjhmYjEiLCJ0b2tlbkRhdGUiOiIyMDIyLTA4LTEyVDE1OjA3OjUyLjAwOVoiLCJpYXQiOjE2NjAzMTY4NzJ9.LSZWr36psPXPuH3j3Sbnkaem1SeDVijjrRocQx0pflw"
        },
        body: JSON.stringify({ role })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: GeneralTypes.GET_STATISTICS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getRegisteredUsersEpic = (action$) =>
  action$.pipe(
    ofType(GeneralTypes.GET_REGISTERED_USERS),
    switchMap(({ payload: { groupBy } }) => {
      return ajax({
        url: `${API}admin/registered`,
        method: "POST",
        headers: {  
            "Content-Type": "application/json",
            "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmRiMDFhYTMyOTViZTAwMTYyMjhmYjEiLCJ0b2tlbkRhdGUiOiIyMDIyLTA4LTEyVDE1OjA3OjUyLjAwOVoiLCJpYXQiOjE2NjAzMTY4NzJ9.LSZWr36psPXPuH3j3Sbnkaem1SeDVijjrRocQx0pflw"
        },
        body: JSON.stringify({ groupBy })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: GeneralTypes.GET_REGISTERED_USERS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getProfilesCountEpic = (action$) =>
  action$.pipe(
    ofType(GeneralTypes.GET_PROFILES_COUNT),
    switchMap(({ payload: { groupBy, startDate, endDate } }) => {
      return ajax({
        url: `${API}admin/registered`,
        method: "POST",
        headers: {  
            "Content-Type": "application/json",
            "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmRiMDFhYTMyOTViZTAwMTYyMjhmYjEiLCJ0b2tlbkRhdGUiOiIyMDIyLTA4LTEyVDE1OjA3OjUyLjAwOVoiLCJpYXQiOjE2NjAzMTY4NzJ9.LSZWr36psPXPuH3j3Sbnkaem1SeDVijjrRocQx0pflw"
        },
        body: JSON.stringify({ groupBy, startDate, endDate })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: GeneralTypes.GET_PROFILES_COUNT_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

//   export const articleById = (action$) =>
//   action$.pipe(
//     ofType(GeneralTypes.ARTICLE_BY_ID),
//     switchMap(({ payload }) => {
//       return ajax({
//         url: `${API}admin/article/${payload}`,
//         method: "GET",
//         headers: "",
//         body: "",
//       }).pipe(
//         switchMap(({ response }) => {
//           return ActionsObservable.create((obs) => {
//             obs.next({
//               type: GeneralTypes.ARTICLE_BY_ID_SUCCESS,
//               payload: response.payload,
//             });
//             obs.complete();
//           });
//         }),
//         catchError((err) => {
//           return ActionsObservable.create((obs) => {
//             console.log(err);
//             obs.complete();
//           });
//         })
//       );
//     })
//   );
