import React from "react";
import "./styles.scss";

function TitleComponent({ title, upperTitle, small, mobileSpecial, mobile, removeLine,titleId,smallerTitle,subtitle,MarginTop,marginleft,titleMargin }) {

  return (
    <div
      className={`title-container ${mobile && "mobile"} ${mobileSpecial && "mobile-special"} ${ small && "small" } ${MarginTop && "top"}` }
      style={{marginLeft:`${marginleft}%`,marginTop:`${titleMargin}%`}}
    >
      {upperTitle && <h4 className="uppercase text-red text-lg font-light text-left" style={{marginBottom:"5%"}}>{upperTitle}</h4>}
    {(smallerTitle === 1) ? <h3 className="mb-1" id={`${titleId}`}>{title}</h3> : 
    <h1 id={`${titleId}`}>{title}</h1>}

    {/* {subtitle?.length && 
    <>
    <h3>{title}</h3>
    <h4 className="uppercase">{subtitle}</h4>
    </>} */}

    </div>
  );
}

export default TitleComponent;
