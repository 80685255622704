import React from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";

const Header = () => {
  const location = useLocation().pathname;

  return (
    <div className="header-container">
        <div className="left-header-container">
        <div className="header-logo" onClick={() => history.push("/")} />
        </div>

      <div className="right-header-container">
        <div className="dropdown">
          <div className={`dropbtn ${location === "/fighter" && "selected"} ${location === "/promoter" && "selected"} ${location === "/manager" && "selected"} ${location === "/fan" && "selected"}`}>PROFILES</div>
          <div className="dropdown-content">
            <div className="buttonche" onClick={() => history.push("/fighter")}>FIGHTER</div>
            <div className="buttonche" onClick={() => history.push("/coach")}>COACH</div>
            <div className="buttonche" onClick={() => history.push("/promoter")}>PROMOTER</div>
            <div className="buttonche" onClick={() => history.push("/manager")}>MANAGER</div>
            <div className="buttonche" onClick={() => history.push("/fan")}>FAN</div>
          </div>
        </div>
        <div className="header-element" onClick={() => history.push("/innovations")}>
          <div className={`header-inner-element ${location === "/innovations" && "selected"}`}>
         INNOVATIONS
          </div>
        </div>
        <div className="header-element" onClick={() => history.push("/how")}>
          <div className={`header-inner-element ${location === "/how" && "selected"}`}>
         HOW IT WORKS
          </div>
        </div>
        <div className="header-element" onClick={() => history.push("/about")}>
          <div className={`header-inner-element ${location === "/about" && "selected"}`}>
          ABOUT US
          </div>
        </div>
        <div className="header-element" onClick={() => history.push("/blog")}>
          <div className={`header-inner-element ${location === "/blog" ? "selected" : location === "/article" && "selected"}`}>
          BLOG
          </div>
        </div>
        </div>
    </div>
  );
};

export default Header;
