import { combineReducers } from 'redux'
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import blogs from './blog'
import general from './general'

export default combineReducers({
  starter,
  routerReducer,
  blogs,
  general
})
