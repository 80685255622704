import React from "react";
import "./styles.scss";

function Tabs({ tabs, selected, onSelect, wide, superwide,howitworks }) {
  return (
    <div className={`tabs-container ${wide && "wide"} ${superwide && "superwide"} ${howitworks && "howitworks"}`}>
      {tabs.map((tab,i) => {
        return (
          <div
            className={`tab-element ${selected === tab && "selected"} `}
            onClick={() => onSelect(tab)}
            key={i}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;
