import React, { useState, useEffect, useRef } from "react";
import { Section, TitleComponent, Tabs, SliderComponent, NumberList, StatisticsComponent } from "../../components";
import './styles.scss';
import { useSelector, useDispatch } from "react-redux";
import { setRef } from "../../actions";

const pictureMap = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-stream-phone-1.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-stream-phone-2.png",
};

const pictureMapSectionTwo = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-phone.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-medical-phone-2.png",
};

const pictureMapSectionThree = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-share.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-share-phone-2.png",
  "Step 3": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-share-phone-3.png"
};

const pictureMapSectionFour = {
  "Step 1": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-switch-phone-1.png",
  "Step 2": "https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-switch-phone-2.png",
};

export default function Innovations() {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState("Livestream event");
  const [selectedTabSectionTwo, setSelectedTabSectionTwo] = useState("How it works");
  const [selectedTabSectionThree, setSelectedTabSectionThree] = useState("Share as a link");
  const [selectedTabSectionFour, setSelectedTabSectionFour] = useState("Become a pro");
  const currentRef = useSelector(state => state.general.innovationsRef)

  const streamRef = useRef()
  const recordsRef = useRef()
  const videoMeetingsRef = useRef()
  const activitiesRef = useRef()
  const customShareRef = useRef()
  const smartEngineRef = useRef()
  const switchProfilesRef = useRef()

  const refMap = {
    'stream and watch events': streamRef,
    'medical records and documents': recordsRef,
    'video meetings': videoMeetingsRef,
    'activities': activitiesRef,
    'custom share': customShareRef,
    'smart search engine': smartEngineRef,
    'switch profiles': switchProfilesRef
  }

  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    if (currentRef) {
      refMap[currentRef].current?.scrollIntoView({ behavior: "smooth" })
    }
    // else {
    //   window.scrollTo({ top: 0,  behavior: "smooth" });
    // }

    return (
      dispatch(setRef(''))
    )
  },[currentRef])

  // console.log(currentRef)

  return (
    <div className='innovations-container'>

      <div className="tabs-component-container" ref={streamRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container mt-7">
              <div className="uppercase text-red text-lg font-light text-left">
                  <p>For all users</p>
              </div>
              <h2 className="upper-mobile-title">
                STREAM AND WATCH EVENTS
              </h2>
              <h5 className="inner-mobile-title uppercase">
                 REACH AND ENTERTAIN NEW USERS AROUND THE WORLD
              </h5>
              <div className="background-tab">Livestream event</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-stream-phone-1.png"
                mobile={isMobileVersion}
                reversed
                // height={110}
                title={""}
                // innerMaxHeight={35}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Your event’s location is no longer a limit to your audience. <b>Fight Scout</b> allows you to reach viewers from all over the world. When listing an event within the App a Promoter can set up a Livestream which can also be watched anytime later on. With an easy step by step set up, streaming your content was never easier and you stay in total control.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Watch live events</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-stream-phone-1.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                // height={117}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      All <b>Fight Scout</b> users can enjoy Livestreams and watch cool content from the Combat Sports world directly to their mobile device. See listings within the App or search for upcoming events using the Smart Search option. Some events may be pay per view and others free to watch.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"STREAM AND WATCH EVENTS"} upperTitle={"For all users"} />
                <h3 className="lower-title uppercase" style={{marginLeft:"5%"}}>
                   REACH AND ENTERTAIN NEW USERS AROUND THE WORLD
                </h3>
                <Tabs
                  tabs={["Livestream event", "Watch live events"]}
                  selected={selectedTab}
                  onSelect={(value) => setSelectedTab(value)}
                />
                {selectedTab === "Livestream event" ? (
                  <>
                    <p className="normal-tab-text">
                    Your event’s location is no longer a limit to your audience. <b>Fight Scout</b> allows you to reach viewers from all over the world. When listing an event within the App a Promoter can set up a Livestream which can also be watched anytime later on. With an easy step by step set up, streaming your content was never easier and you stay in total control.
                    </p>
                  </>
                ) : selectedTab === "Watch live events" ? (
                  <>
                    <p className="normal-tab-text">
                    All <b>Fight Scout</b> users can enjoy Livestreams and watch cool content from the Combat Sports world directly to their mobile device. See listings within the App or search for upcoming events using the Smart Search option. Some events may be pay per view and others free to watch.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="normal-tab-text">
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-stream-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={
                selectedTab === "Livestream event"
              ? pictureMap["Step 1"]
              : selectedTab === "Watch live events" && pictureMap["Step 2"]} />
            </div>
          </>
        )}
      </div>

      <div className="tabs-component-container" ref={recordsRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
                <div className="uppercase text-red text-lg font-light text-left">
                  <p>For fighters</p>
                </div>
              <h2 className="upper-mobile-title">
                MEDICAL RECORDS AND DOCUMENTS
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Get your documents for a fight reviewed through the app
              </h5>
              <div className="background-tab">How it works</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/medical-records-phone.png"
                mobile={isMobileVersion}
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      The medical records and documents functionality is growing each day. All you have to do is add an event you are participating in from your profile. If the location lands in an area, in which the app is partnering with the commissioners, you will be able to review and send directly all needed documentation. The list is based on your gender, age and sport type.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Commissioners</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-medical-phone-2.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Communicate with Governing Bodies and commissions quickly and directly within the App. Any issues can be dealt immediately and efficiently meaning no stress and your opportunity not being compromised.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-medical-background.png")`,
              }}
            >
              <img 
              className="main-image"
              src={selectedTabSectionTwo === "How it works"
              ? pictureMapSectionTwo["Step 1"]
              : selectedTabSectionTwo === "Commissioners" && pictureMapSectionTwo["Step 2"]}
               />
            </div>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"MEDICAL RECORDS AND DOCUMENTS"} upperTitle={"For fighters"} />
                <h3 className="lower-title uppercase" style={{marginLeft:"5%"}}>
                  Get your documents for a fight reviewed through the app
                </h3>
                <Tabs
                  tabs={["How it works", "Commissioners"]}
                  selected={selectedTabSectionTwo}
                  onSelect={(value) => setSelectedTabSectionTwo(value)}
                />
                {selectedTabSectionTwo === "How it works" ? (
                  <>
                    <p className="normal-tab-text">
                    The medical records and documents functionality is growing each day. All you have to do is add an event you are participating in from your profile. If the location lands in an area, in which the app is partnering with the commissioners, you will be able to review and send directly all needed documentation. The list is based on your gender, age and sport type.
                    </p>
                  </>
                ) : selectedTabSectionTwo === "Commissioners" ? (
                  <>
                  <p className="normal-tab-text">
                  Communicate with Governing Bodies and commissions quickly and directly within the App. Any issues can be dealt immediately and efficiently meaning no stress and your opportunity not being compromised.
                  </p>
                  </>
                ) : (
                  <>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="tabs-component-container w-full h-fit" ref={videoMeetingsRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
                <div className="uppercase text-red text-lg font-light text-left">
                  <p>For professionals</p>
                </div>
              <h2 className="upper-mobile-title">
                VIDEO MEETINGS
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Communicate easier than ever before
              </h5>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-video-phone.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                    <p className="normal-tab-text">We at <b>Fight Scout</b> know how important a call can be. That’s why we’ve added video meetings to our chat. Schedule the time via the chat with the desired user and enjoy the benefits from online communication. You can schedule as many meetings, as you desire with no additional charge, based on the length of the meeting.</p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-video-phone.png"
            image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-video-background.png")`}
            title="VIDEO MEETINGS"
            profile="for professionals"
            invert
            mobile={isMobileVersion}
            height={101}
            renderContent={()=>{return(<>
                    <div className="line-and-text">
                        <h3 className="lower-title uppercase">Communicate easier than ever before</h3>
                        <br/>
                        <p className="font-light">We at <b>Fight Scout</b> know how important a call can be. That’s why we’ve added video meetings to our chat. Schedule the time via the chat with the desired user and enjoy the benefits from online communication. You can schedule as many meetings, as you desire with no additional charge, based on the length of the meeting.</p>
                    </div>  
              </>)
              
            }}
            />
          </>
        )}
      </div>

      <div className="tabs-component-container w-full h-fit" ref={activitiesRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
                <div className="uppercase text-red text-lg font-light text-left">
                  <p>For all users</p>
                </div>
              <h2 className="upper-mobile-title">
                ACTIVITIES
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Reach new users and share your thoughts
              </h5>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-activities-phone.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                    <p className="normal-tab-text">It is crucial for each profile to keep people interested. Our activities function allows you to improve your engagement by posting daily activities in the form of videos, photos and text. The more you post, the more users you will reach. And if you like how a certain activity turned out, you can share it in all other social media platforms with our custom sharing option.</p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-activities-phone.png"
            image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-activities-background.png")`}
            title="ACTIVITIES"
            profile="all users"
            mobile={isMobileVersion}
            height={101}
            renderContent={()=>{return(<>
                    <div className="line-and-text">
                        <h3 className="lower-title uppercase">Reach new users and share your thoughts</h3>
                        <br/>
                        <p className="font-light">It is crucial for each profile to keep people interested. Our activities function allows you to improve your engagement by posting daily activities in the form of videos, photos and text. The more you post, the more users you will reach. And if you like how a certain activity turned out, you can share it in all other social media platforms with our custom sharing option.</p>
                    </div>  
              </>)
              
            }}
            />
          </>
        )}
      </div>

      <div className="tabs-component-container" ref={customShareRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
              <div className="uppercase text-red text-lg font-light text-left">
                  <p>For all users</p>
                </div>
              <h2 className="upper-mobile-title">
                CUSTOM SHARE
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Share content cross platform
              </h5>
              <div className="background-tab">Share as a link</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/fan-share.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      You can share each combat sports content, such as event, profile or activity, as a link from <b>Fight Scout</b> to any other message or social media app.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Share as a story</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-share-phone-2.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Running multiple profiles in different social media apps can be difficult. That’s why we allow you to re-share content in a story format from the <b>Fight Scout</b> app to any other application. You can use it for your own content or all other public content in the app.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Share as a post</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-share-phone-3.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      Running multiple profiles in different social media apps can be difficult. That’s why we allow you to re-share content in a post format from the <b>Fight Scout</b> app to any other application. You can use it for your own content or all other public content in the app.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"CUSTOM SHARE"} upperTitle={"For all users"} />
                <h3 className="lower-title uppercase" style={{marginLeft:"5%"}}>
                  Share content cross platform
                </h3>
                <Tabs
                  tabs={["Share as a link", "Share as a story", "Share as a post"]}
                  selected={selectedTabSectionThree}
                  onSelect={(value) => setSelectedTabSectionThree(value)}
                />
                {selectedTabSectionThree === "Share as a link" ? (
                  <>
                    <p className="normal-tab-text">
                    You can share each combat sports content, such as event, profile or activity, as a link from <b>Fight Scout</b> to any other message or social media app.
                    </p>
                  </>
                ) : selectedTabSectionThree === "Share as a story" ? (
                  <>
                    <p className="normal-tab-text">
                    Running multiple profiles in different social media apps can be difficult. That’s why we allow you to re-share content in a story format from the <b>Fight Scout</b> app to any other application. You can use it for your own content or all other public content in the app.
                    </p>
                  </>
                ) : selectedTabSectionThree === "Share as a post" ? (
                  <>
                    <p className="normal-tab-text">
                    Running multiple profiles in different social media apps can be difficult. That’s why we allow you to re-share content in a post format from the <b>Fight Scout</b> app to any other application. You can use it for your own content or all other public content in the app.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="normal-tab-text">
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-share-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={
                selectedTabSectionThree === "Share as a link"
              ? pictureMapSectionThree["Step 1"]
              : selectedTabSectionThree === "Share as a story"
              ? pictureMapSectionThree["Step 2"]
              : selectedTabSectionThree === "Share as a post" && pictureMapSectionThree["Step 3"]}
               />
            </div>
          </>
        )}
      </div>

      <div className="tabs-component-container w-full h-fit" ref={smartEngineRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
                <div className="uppercase text-red text-lg font-light text-left">
                  <p>For all users</p>
                </div>
              <h2 className="upper-mobile-title">
                SMART SEARCH ENGINE
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Combine search criteria and discover new content
              </h5>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-search-phone.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                    <p className="normal-tab-text">With constantly growing content in the app, each day users have more and more to discover. The smart search engine allows you to search in a specific category by custom criteria, to allow you to reach the desired and best content for you.</p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-search-phone.png"
            image={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-search-background.png")`}
            title="SMART SEARCH ENGINE"
            profile="for professionals"
            // invert
            mobile={isMobileVersion}
            height={101}
            renderContent={()=>{return(<>
                    <div className="line-and-text">
                        <h3 className="lower-title uppercase">Combine search criteria and discover new content</h3>
                        <br/>
                        <p className="font-light">With constantly growing content in the app, each day users have more and more to discover. The smart search engine allows you to search in a specific category by custom criteria, to allow you to reach the desired and best content for you.</p>
                    </div>  
              </>)
              
            }}
            />
          </>
        )}
      </div>

      <div className="tabs-component-container" ref={switchProfilesRef}>
        {window.innerWidth < 900 ? (
          <>
            <div className="mobile-tabs-container">
                <div className="uppercase text-red text-lg font-light text-left">
                  <p>For all users</p>
                </div>
              <h2 className="upper-mobile-title">
                SWITCH PROFILES
              </h2>
              <h5 className="inner-mobile-title uppercase">
                Reach new users and entertain
              </h5>
              <div className="background-tab">Become a pro</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-switch-phone-1.png"
                mobile={isMobileVersion}
                // reversed
                title={""}
                renderContent={() => {
                  return (
                      <p>
                      If you’ve had a fan account, but interest in the combat sports industry has pushed you into starting your own journey, you can switch to a professional profile any time by upgrading your account to a pro, with no additional charges.
                      </p>
                  );
                }}
              />
              <div className="background-tab">Multiple profiles</div>
              <Section
                mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-switch-phone-2.png"
                mobile={isMobileVersion}
                reversed
                title={""}
                renderContent={() => {
                  return (
                      <p className="normal-tab-text">
                      <b>Fight Scout</b> allows you to maintain multiple profiles under one account. This will allow you to switch fast and smooth between profiles, while keeping each one’s specific content and functionalities.
                      </p>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="content-container not-blend">
              <div className="text-inner-container">
                <TitleComponent title={"SWITCH PROFILES"} upperTitle={"For all users"} />
                <h3 className="lower-title uppercase" style={{marginLeft:"5%"}}>
                  Reach new users and entertain
                </h3>
                <Tabs
                  tabs={["Become a pro", "Multiple profiles"]}
                  selected={selectedTabSectionFour}
                  onSelect={(value) => setSelectedTabSectionFour(value)}
                />
                {selectedTabSectionFour === "Become a pro" ? (
                  <>
                    <p className="normal-tab-text">
                     If you’ve had a fan account, but interest in the combat sports industry has pushed you into starting your own journey, you can switch to a professional profile any time by upgrading your account to a pro, with no additional charges.
                    </p>
                  </>
                ) : selectedTabSectionFour === "Multiple profiles" ? (
                  <>
                  <p className="normal-tab-text">
                    <b>Fight Scout</b> allows you to maintain multiple profiles under one account. This will allow you to switch fast and smooth between profiles, while keeping each one’s specific content and functionalities.
                  </p>
                  </>
                ) : (
                  <>
                  </>
                )}
              </div>
            </div>
            
            <div
              className="image"
              style={{
                backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-switch-background.png")`,
              }}
            >
              <img 
              className="tab-image"
              src={
                selectedTabSectionFour === "Become a pro"
              ? pictureMapSectionFour["Step 1"]
              : selectedTabSectionFour === "Multiple profiles" && pictureMapSectionFour["Step 2"]} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
