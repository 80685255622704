import React, { useState, useEffect } from "react";
import {
  Section,
  TitleComponent,
  ContactComponent,
} from "../../components";
import "./styles.scss";

const textMap = [
  {
    title: "COMBAT SPORTS SPECIALISTS",
    text: `As a former Professional MMA Fighter and Champion as well as Brazillian Jiu Jitsu Black Belt and ADCC Cheif Official who has coached many champions in various disciplines and promoted World class events in several countries around the globe. Lubomir Guedjev what been a combat sports innovator. He is a renowned motivator and highly respected leader.`,
  },
  {
    title: "AWARD WINNING UX/UI EXPERTS",
    text: `With over "x" software solutions and mobile apps developed for various high level clients in numerous fields, FidWeb took on this project with passion. It matches the organization's vision of creating products that maximise benefits to people and industries.`,
  },
  {
    title: "SUCCESS DRIVEN MINDS",
    text: `From concept to development, this incredible project would have been impossible, without the trust and generosity of our investors. Success driven minds find each other and our team has combined the best of the three worlds - creativity, expertise and commitment.`,
  },
];

const pictureMap = {
  0: "https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-1.png",
  1: "https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-2.png",
  2: "https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-3.png",
};

const About = () => {
  const [selected, setSelected] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="property-component-container">
      {isMobileVersion === 1 ? (
        <div>
          <TitleComponent
            title={"WHO ARE WE"}
            smallerTitle={3}
            marginleft={5}
            titleMargin={5}
          />
          <h3 className="font-light" style={{ fontSize: "18px", marginLeft: "5%", marginTop: "2%",lineHeight:"1.3em" }}>
            WORKING WITH THE BEST IN THE INDUSTRY
          </h3>
          <h5 className="font-bold mt-8" style={{ marginLeft: "5%", fontSize: "18px" }}>COMBAT SPORTS SPECIALISTS</h5>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-1.png"
            reversed
            title={""}
            mobile={isMobileVersion}
            // height={80}
            // innerMaxHeight={40}
            renderContent={() => {
              return (
                  <div className="line-and-text padding">
                    <p className="mt-3">
                    As a former Professional MMA Fighter and Champion as well as Brazillian Jiu Jitsu Black Belt and ADCC Cheif Official who has coached many champions in various disciplines and promoted World class events in several countries around the globe. Lubomir Guedjev what been a combat sports innovator. He is a renowned motivator and highly respected leader.
                    </p>
                  </div>
              );
            }}
          />
          <h5 className="font-bold text-left padding" style={{ fontSize: "18px" }}>AWARD WINNING UX/UI EXPERTS</h5>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-2.png"
            reversed
            title={""}
            mobile={isMobileVersion}
            renderContent={() => {
              return (
                  <div className="line-and-text padding">
                    <p className="mt-3">With over “x” software solutions and mobile apps developed for various high level clients in numerous fields, FidWeb took on this project with passion. It matches the organization’s vision of creating products that maximise benefits to people and industries.</p>
                  </div>
              );
            }}
          />
          <div className="mb-10" />
          <h5 className="font-bold text-left padding" style={{ fontSize: "18px" }}>SUCCESS DRIVEN MINDS</h5>
          <Section
            mainImage="https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-1-3.png"
            reversed
            title={""}
            mobile={isMobileVersion}
            renderContent={() => {
              return (
                  <div className="line-and-text padding">
                    <p className="mt-3">From concept to development, this incredible project would have been impossible, without the trust and generosity of our investors. Success driven minds find each other and our team has combined the best of the three worlds - creativity, expertise and commitment.</p>
                  </div>
              );
            }}
          />
          <div className="mb-10" />
        </div>
      ) : (
        <Section
          image={`url(${pictureMap[selected]})`}
          title={"WHO ARE WE"}
          invert
          blended
          renderContent={() => {
            return (
              <>
                {/* <TitleComponent
                  title={"WORKING WITH THE BEST IN THE INDUSTRY"}
                  smallerTitle={1}
                /> */}
              <h3 style={{ fontSize: "22px", fontFamily: '$font-family-light' }}>
                WORKING WITH THE BEST IN THE INDUSTRY
              </h3>

                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container ${
                        selected === 0 && "selected"
                      }`}
                      onClick={() => setSelected(0)}
                    >
                      <div className="linee-and-text">
                        <div className="leftt-line" />
                        <h7
                          className={`tab-title ${
                            selected === 0 && "selected"
                          }`}
                          style={{fontSize:"24px"}}
                        >
                          {textMap[0].title}
                        </h7>
                      </div>
                      <div className="arrow-container">
                        <div className="line" />
                        <div className="arrow-right" />
                      </div>
                    </div>
                    <p className={`text-info ${selected === 0 && "selected"}`}>
                      {textMap[0].text}
                    </p>
                  </div>

                  <div className="wrapper">
                    <div
                      className={`flex-container ${
                        selected === 1 && "selected"
                      }`}
                      onClick={() => setSelected(1)}
                    >
                      <div className="linee-and-text">
                        <div className="leftt-line" />
                        <h7
                          className={`tab-title ${
                            selected === 1 && "selected"
                          }`}
                          style={{fontSize:"24px"}}
                        >
                          {textMap[1].title}
                        </h7>
                      </div>
                      <div className="arrow-container">
                        <div className="line" />
                        <div className="arrow-right" />
                      </div>
                    </div>
                    <p className={`text-info ${selected === 1 && "selected"}`}>
                      {textMap[1].text}
                    </p>
                  </div>

                  <div className="wrapper">
                    <div
                      className={`flex-container ${
                        selected === 2 && "selected"
                      }`}
                      onClick={() => setSelected(2)}
                    >
                      <div className="linee-and-text">
                        <div className="leftt-line" />
                        <h7
                          className={`tab-title ${
                            selected === 2 && "selected"
                          }`}
                          style={{fontSize:"24px"}}
                        >
                          {textMap[2].title}
                        </h7>
                      </div>
                      <div className="arrow-container">
                        <div className="line" />
                        <div className="arrow-right" />
                      </div>
                    </div>
                    <p className={`text-info ${selected === 2 && "selected"}`}>
                      {textMap[2].text}
                    </p>
                  </div>
                </div>
              </>
            );
          }}
        />
      )}

      {isMobileVersion === 1 ? (
        <ContactComponent mobile={1} />
      ) : (
        <ContactComponent />
      )}
    </div>
  );
};

export default About;
