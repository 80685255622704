export const GeneralTypes = {
    SET_REF: 'general/SET_REF',
    GET_STATISTICS: 'general/GET_STATISTICS',
    GET_STATISTICS_SUCCESS: 'general/GET_STATISTICS_SUCCESS',
    GET_REGISTERED_USERS: 'general/GET_REGISTERED_USERS',
    GET_REGISTERED_USERS_SUCCESS: 'general/GET_REGISTERED_USERS_SUCCESS',
    GET_PROFILES_COUNT: 'general/GET_PROFILES_COUNT',
    GET_PROFILES_COUNT_SUCCESS: 'general/GET_PROFILES_COUNT_SUCCESS',
  }
  
  export const setRef = (payload) => ({
    type: GeneralTypes.SET_REF,
    payload
  })

  export const getStatistics = (payload) => ({
    type: GeneralTypes.GET_STATISTICS,
    payload
  })

  export const getRegisteredUsers = (payload) => ({
    type: GeneralTypes.GET_REGISTERED_USERS,
    payload
  })

  export const getProfilesCount = (payload) => ({
    type: GeneralTypes.GET_PROFILES_COUNT,
    payload
  })