import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { blogTypes } from "../actions";
import { ajax } from "rxjs/ajax";
import { API } from "../config/settings";

export const articlesList = (action$) =>
  action$.pipe(
    ofType(blogTypes.ARTICLES_LIST),
    switchMap(({ payload: { pageNumber, pageSize, noPagination, search } }) => {
      return ajax({
        url: `${API}admin/article/list`,
        method: "POST",
        headers: "",
        body: JSON.stringify({ pageNumber, pageSize, noPagination, search }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: blogTypes.ARTICLES_LIST_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

  export const articleById = (action$) =>
  action$.pipe(
    ofType(blogTypes.ARTICLE_BY_ID),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}admin/article/${payload}`,
        method: "GET",
        headers: "",
        body: "",
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: blogTypes.ARTICLE_BY_ID_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );
