import React, { useState, useEffect } from "react";
import {
  Section,
  MainComponent,
  TabsComponent,
  ContactComponent,
} from "../../components";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.css";

const Quiz = () => {
  useEffect(() => {
    if (window.innerWidth < 900) {
      document.getElementsByClassName(
        "mobile-full-footer-container"
      )[0].style.display = "none";
      document.getElementsByClassName("mobile-container")[0].style.display =
        "none";
    } else {
      document.getElementsByClassName("header-container")[0].style.display =
        "none";
      document.getElementsByClassName(
        "full-footer-container"
      )[0].style.display = "none";
    }
  }, []);
  return (
    <div className="background-quiz">
      <img
        src="https://images.typeform.com/images/M2TxzZKZPhZM/image/default-firstframe.png"
        className="splash"
      />
      <br />
      <div className="lang">
        <a href="https://1cbr0pqre8p.typeform.com/to/nFRAY3ic">
          Choose your language:
          <br />
          <img src="https://i.imgur.com/RhPAD9d.png" />
        </a>
      </div>
      <div className="lang">
        <a href="https://1cbr0pqre8p.typeform.com/to/kVxt7Iv3">
          Escolha seu idioma:
          <br />
          <img src="https://i.imgur.com/9Rm0Mc7.png" />
        </a>
      </div>
      <div className="lang">
        <a href="https://1cbr0pqre8p.typeform.com/to/vO2wyOav">
          Seleciona su lenguaje:
          <br />
          <img src="https://i.imgur.com/K0mL8QF.png" />
        </a>
      </div>
    </div>
  );
};

export default Quiz;
