import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import { Icon } from "react-icons-kit";
import {menu} from 'react-icons-kit/entypo/menu'
import { close } from "react-icons-kit/fa/close";

const MobileHeader = () => {
  const location = useLocation().pathname;

  const [open, setOpen] = useState(false);
  const [openprofile,setOpenprofile] = useState(false);

  return (
    <div className="mobile-container">
      <div
        className="button"
        style={{alignItems:"center",justifyContent:"center"}}
        onClick={() => {
          setOpen(true);
          document.body.style.overflow = "hidden";
        }}
      >
        <div className="hamburger"/>
      </div>
      <div className="mobile-header-logo" onClick={() => history.push("/")} />
      {open && (
        <div className="opened-header">
          <div className="upper-opened-header">
            <div
              className="close-opened-header"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
              }}
            >
              <div className="close-container"/>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/" && "selected"
                }`}
              >
                HOME
              </div>
            </div>
            <div className="mobile-header-element-and-list">
              <div
                className="mobile-header-element"
                onClick={() => {
                  if(openprofile === false){
                  setOpenprofile(true);
                  document.getElementById("list").style.display="block";
                }
                else{
                  setOpenprofile(false);
                  document.getElementById("list").style.display="none";
                }
                }}
              >
              <div
                className={`mobile-header-inner-element ${location === "/fighter" && "selected"} ${location === "/promoter" && "selected"} ${location === "/manager" && "selected"} ${location === "/fan" && "selected"}`}>
                PROFILES
              </div>
              </div>
              <div className="mobile-list" id="list">
              <div className="list-fighter" onClick={() => {
                setOpen(false);
                setOpenprofile(false);
                document.body.style.overflow = "visible";
                history.push("/fighter");
              }}>FIGHTER</div>
                <div className="list-coach" onClick={() => {
                setOpen(false);
                setOpenprofile(false);
                document.body.style.overflow = "visible";
                history.push("/coach");
              }}>COACH</div>
              <div className="list-promoter" onClick={() => {
                setOpen(false);
                setOpenprofile(false);
                document.body.style.overflow = "visible";
                history.push("/promoter");
              }}>PROMOTER</div>
              <div className="list-manager" onClick={() => {
                setOpen(false);
                setOpenprofile(false);
                document.body.style.overflow = "visible";
                history.push("/manager");
              }}>MANAGER</div>
              <div className="list-fan" onClick={() => {
                setOpen(false);
                setOpenprofile(false);
                document.body.style.overflow = "visible";
                history.push("/fan");
              }}>FAN</div>
            
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/how");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/how" && "selected"
                }`}
              >
                HOW IT WORKS
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/innovations");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/innovations" && "selected"
                }`}
              >
                INNOVATIONS
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/about");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/about" && "selected"
                }`}
              >
                ABOUT US
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/blog");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/blog" ? "selected" : location === "/article" ? "selected" : ""
                }`}
              >
                BLOG
              </div>
            </div>
            <div className="mobile-flex-container-apps">
            <div
              className="app-store white"
              onClick={()=>{window.open("https://apps.apple.com/bg/app/fightscout/id1590863097?fbclid=IwAR13u_6XIomgZqkcJJuSPiXTrcSVrW94ALkVS2MOMTGHJl9ohpIIVpsKQ2U")}}
            />
            <div
              className="google-play white"
              onClick = {() => {window.open("https://play.google.com/store/apps/details?id=com.socourt.combater")}}
            />
          </div>
          </div>
          <div className="lower-opened-header"></div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;