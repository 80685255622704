import React from 'react';
import './styles.scss';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setRef } from "../../actions";
import { history } from '../../config/stores';

const SliderComponent = ({
    renderContent,
    title,
    innovationsRef,
    backgroundImage,
    subtitle,
    smallSlides,
    innovations,
    profile,
    data,
    homePage,
    customSLides
  }) => {

    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    const [ isSliderActive, setIsSliderActive ] = useState(false)
    const dispatch = useDispatch();
    // const [ data, setData ] = useState([])

    // const dataInnovations = [
    //   { icon: 'innovations-icon-1.svg', title: 'Stream and watch events', text: 'Promoters can stream their events directly in the app for all users to watch. Fight Scout closes the circle by providing a streaming app and web panel to control all streams.' },
    //   { icon: 'innovations-icon-2.svg', title: 'Medical records and documents', text: 'The app allows fighters to submit the needed documentaion for participation in an event directly to commissioners for certain areas of the world.' },
    //   { icon: 'innovations-icon-3.svg', title: 'Video meetings', text: 'The networking aspect of the app is covered by the rich in functionality chat. One of the key ones is scheduling and connecting a video meeting.' },
    //   { icon: 'innovations-icon-4.svg', title: 'Activities', text: 'Day to day activities allow all pro users to share their days, achievements and all related events by posting in the activities feed of the app.' },
    //   { icon: 'innovations-icon-5.svg', title: 'Custom share', text: 'This function allows users to share each profile, event or activity to all other social media platforms with custom look.' },
    //   { icon: 'innovations-icon-6.svg', title: 'Smart search engine', text: 'With endless content, the app has the option for users to do a specific search for all profiles and events by selection one or more of many different criteria and combining them for optimal results.' },
    //   { icon: 'innovations-icon-7.svg', title: 'Switch profiles', text: 'We know that ours users wear many hats, that’s why we allow each account to have multiple profiles attach to it.' },
    // ]
    // const dataFighters = [
    //   { img: 'fighter-slider-img-1.png', title: 'Elena Solano', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'John Tompson', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' },
    //   { img: 'fighter-slider-img-1.png', title: 'Adrian Doe', nationality: 'GB', flag: 'flag-GB.png' }
    // ]

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendDots: dots => (
          <div
          className='slick-dots'
            style={{
              marginTop: "20px",
              width: "100%",
            }}
          >
            <ul> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div
          className='slider-inactive'
          />
        ),
    };

    const settingsFighter = {
        dots: true,
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        appendDots: dots => (
          <div
          className='slick-dots'
            style={{
              marginTop: "20px",
              width: "100%",
            }}
          >
            <ul style={{ margin: "0 auto", width: "fit-content", display: "flex" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div
          className='slider-inactive'
            style={{
              marginRight: "10px",
              padding: "0 15px",
              width: "70px",
              height: "8px",
              borderRadius: "8px",
              border: "1px white solid",
              // backgroundColor: "#E5214E"
            }}
          />
        ),
    };

    const settingsCoach = {
      dots: true,
      arrows: false,
      infinite: true,
      swipeToSlide: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 6,
      appendDots: dots => (
        <div
        className='slick-dots'
          style={{
            marginTop: "20px",
            width: "100%",
          }}
        >
          <ul style={{ margin: "0 auto", width: "fit-content", display: "flex" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
        className='slider-inactive'
          style={{
            marginRight: "10px",
            padding: "0 15px",
            width: "70px",
            height: "8px",
            borderRadius: "8px",
            border: "1px white solid",
            // backgroundColor: "#E5214E"
          }}
        />
      ),
    };

    const navigateOnClick = (link, ref) => {
      if (window.location.pathname === '/') {
        history.push(link)
        dispatch(setRef(ref))
      }
    }
  // useEffect(() => {
  //   if (title === "INNOVATIONS") {
  //     setData(dataInnovations)
  //   }
  //   else if (title === "FRESH FROM THE APP") {
  //     setData(dataFighters)
  //   }
  // }, [title]);

  return (
    <div className={`section-container slider ${innovations && 'innovations'} ${profile && 'profile'}`}>
      <div className='container content'>
        <div className={`container-inner flex-col content-center ${smallSlides ? 'wide' : ''} ${isMobileVersion === 1 ? 'mobile h-full' : ''}`}>
          {title && <h1 className={`title ${smallSlides ? 'mb-1' : 'mb-8'} uppercase text-center`}>{title}</h1>}
          {subtitle && <h2 className={`subtitle big mb-10 uppercase ${isMobileVersion === 1 ? 'text-left ml-0' : 'text-center'}`}>{subtitle}</h2>}
        {/* {(!smallSlides && data) &&
        (!isMobileVersion === 1 ? 
         <div className="cards-wrapper desktop flex">
          <div className='cards-wrapper desktop h-full'>
            <Slider {...settings}>
                {data?.map(el => {
                     return (
                      <div className='w-full cursor-pointer' onClick={() => {
                        if (el?.ref) {
                          navigateOnClick('/innovations', el.ref)
                        }
                       }}>
                        <div className='card-wrapper flex-col items-center justify-between'>
                           <div className='top flex-col items-center justify-center'>
                           {el.icon && <img className='mt-10 mb-10' src={require(`../../assets/icons/${el.icon}`)} />}
                               <div className='flex-col text-center justify-center items-center mt-8'>
                                 <h4 className='smaller-title pb-2'>{el.title}</h4>
                                 <h5 className='subtitle'>{el.text}</h5>
                               </div>
                            </div>
                           
                           <div className='bottom text-end'>
                               <h4 className='underline via-red cursor-pointer'>Explore</h4>
                           </div>
                        </div>
                       </div>
                     )
                 })}
             </Slider>
          </div>
        </div> : 
        <div className="cards-wrapper mobile flex">
           {data?.map(el => {
              return (
               <div className='w-full cursor-pointer' onClick={() => {
                 if (el?.ref) {
                   navigateOnClick('/innovations', el.ref)
                 }
                }}>
                 <div className='card-wrapper flex-col items-center justify-between'>
                    <div className='top flex-col items-center justify-center'>
                    {el.icon && <img src={require(`../../assets/icons/${el.icon}`)} />}
                        <div className='flex-col text-center justify-center items-center mt-2'>
                          <h4 className='smaller-title'>{el.title}</h4>
                          <h5 className='subtitle'>{el.text}</h5>
                        </div>
                     </div>
                    
                    <div className='bottom text-end'>
                        <h4 className='underline via-red cursor-pointer'>Explore</h4>
                    </div>
                 </div>
                </div>
              )
          })}
        </div>)} */}

        {(!smallSlides && data?.length && isMobileVersion === 0) &&
                 <div className="cards-wrapper desktop flex">
                 <div className='cards-wrapper desktop h-full'>
                   <Slider {...settings}>
                       {data?.map((el, index) => {
                            return (
                             <div className='w-full cursor-pointer'
                             key={`card-wrapper-desktop-${index}`}
                              onClick={() => {
                               if (el?.ref) {
                                 navigateOnClick('/innovations', el.ref)
                               }
                              }}>
                               <div className={`card-wrapper flex-col items-center justify-between mt-auto ${el?.zoom ? 'zoom' : ''} ${el?.zoomExtra ? 'zoom-extra' : ''} ${el?.zoomCenter ? 'zoom-center' : ''} ${el?.zoomRight ? 'zoom-right' : ''}`}>
                                  <div className='top flex-col items-center justify-center'>
                                  {el.icon && <img className='mt-10 mb-10' src={require(`../../assets/icons/${el.icon}`)} />}
                                      <div className='flex-col text-center justify-center items-center mt-8'>
                                        <h4 className='smaller-title pb-2'>{el.title}</h4>
                                        <h5 className='subtitle'>{el.text}</h5>
                                      </div>
                                   </div>
                                  
                                  <div className='bottom text-end'>
                                      <h4 className='underline via-red cursor-pointer'>Explore</h4>
                                  </div>
                               </div>
                              </div>
                            )
                        })}
                    </Slider>
                 </div>
               </div>}

        {(!smallSlides && data?.length && isMobileVersion === 1) &&
          <div className="cards-wrapper mobile flex">
                {data?.map((el, index) => {
                   return (
                    <div className='w-full cursor-pointer'
                    key={`card-wrapper-mobile-${index}`}
                     onClick={() => {
                      if (el?.ref) {
                        navigateOnClick('/innovations', el.ref)
                      }
                     }}>
                      <div className={`card-wrapper flex-col items-center justify-between ${!homePage ? 'not-home-page' : ''} ${el?.zoom ? 'zoom' : ''} ${el?.zoomExtra ? 'zoom-extra' : ''} ${el?.zoomCenter ? 'zoom-center' : ''} ${el?.zoomRight ? 'zoom-right' : ''}`}>
                         <div className='top flex-col items-center justify-center'>
                         {el.icon && <img src={require(`../../assets/icons/${el.icon}`)} />}
                             <div className='flex-col text-center justify-center items-center mt-2'>
                               <h4 className='smaller-title'>{el.title}</h4>
                               <h5 className='subtitle'>{el.text}</h5>
                             </div>
                          </div>
                         
                         <div className='bottom text-end'>
                             <h4 className='underline via-red cursor-pointer'>Explore</h4>
                         </div>
                      </div>
                     </div>
                   )
               })}
             </div>}

        {(smallSlides && data?.length && isMobileVersion === 0 && customSLides !== 'coach') &&
        <div className="cards-wrapper desktop fighters-slider">
            <Slider {...settingsFighter}>
                 {data?.map((el, index) => {
                      return (
                       <div className='w-fit'  key={`card-wrapper-settings-${index}`}>
                         <div className={`card-wrapper flex-col items-center justify-between ${el?.zoom ? 'zoom' : ''} ${el?.zoomExtra ? 'zoom-extra' : ''} ${el?.zoomCenter ? 'zoom-center' : ''} ${el?.zoomRight ? 'zoom-right' : ''}`}>
                          <img className='background-img' src={el?.img} alt="fighter" />
                            <div className='bottom text-end'>
                              <div className='flex justify-between'>
                                <h4 className='smallest-title'>{el.title}</h4>

                                <div className='flex flex-col items-center bg-black'>
                                  {/* {el?.flag && <img src={`https://fightscout.s3.eu-central-1.amazonaws.com/images/${el?.flag}`} />} */}
                                  {el?.flag && <img src={require(`../../assets/images/${el?.flag}`)} />}
                                  <h5 className='bold uppercase'>{el?.nationality}</h5>
                                </div>
                              </div>
                            </div>
                         </div>
                        </div>
                      )
                  })}
             </Slider>
        </div>}

        {(smallSlides && data?.length && isMobileVersion === 0 && customSLides === 'coach') &&
        <div className="cards-wrapper desktop fighters-slider coach">
            <Slider {...settingsCoach}>
                 {data?.map((el, index) => {
                      return (
                       <div className='w-fit' key={`card-wrapper-settings-${index}`}>
                         <div className={`card-wrapper flex-col items-center justify-between ${el?.zoom ? 'zoom' : ''} ${el?.zoomExtra ? 'zoom-extra' : ''} ${el?.zoomCenter ? 'zoom-center' : ''} ${el?.zoomRight ? 'zoom-right' : ''}`}>
                          <img className='background-img' src={el?.img} alt="fighter" />
                            <div className='bottom text-end'>
                              <div className='flex justify-between'>
                                <h4 className='smallest-title'>{el.title}</h4>

                                <div className='flex flex-col items-center bg-black'>
                                 {/* {el?.flag && <img src={`https://fightscout.s3.eu-central-1.amazonaws.com/images/${el?.flag}`} />} */}
                                 {el?.flag && <img src={require(`../../assets/images/${el?.flag}`)} />}
                                  <h5 className='bold uppercase'>{el?.nationality}</h5>
                                </div>
                              </div>
                            </div>
                         </div>
                        </div>
                      )
                  })}
             </Slider>
        </div>}

        {(smallSlides && data?.length && isMobileVersion === 1) &&
          <div className="cards-wrapper mobile flex">
                 {data?.map((el, index) => {
                      return (
                    <div className='w-full cursor-pointer'
                    key={`card-wrapper-mobile-1-${index}`}
                     onClick={() => {
                      if (el?.ref) {
                        navigateOnClick('/innovations', el.ref)
                      }
                     }}>
                      <div className={`card-wrapper flex-col items-center justify-between ${!homePage ? 'not-home-page' : ''} ${el?.zoom ? 'zoom' : ''} ${el?.zoomExtra ? 'zoom-extra' : ''} ${el?.zoomCenter ? 'zoom-center' : ''} ${el?.zoomRight ? 'zoom-right' : ''}`}>
                          <img className='background-img' src={el?.img} alt="fighter" />
                            <div className='bottom text-end'>
                              <div className='flex justify-between items-center pt-5 pb-5 pl-3 pr-3'>
                                <h4 className='smallest-title bold text-white mb-0'>{el.title}</h4>

                                <div className='flex flex-col items-center bg-black'>
                                  {/* {el?.flag && <img src={`https://fightscout.s3.eu-central-1.amazonaws.com/images/${el?.flag}`} />} */}
                                  {el?.flag && <img src={require(`../../assets/images/${el?.flag}`)} />}
                                  <h5 className='bold uppercase'>{el?.nationality}</h5>
                                </div>
                              </div>
                            </div>
                      </div>
                     </div>
                      )
                  })}
        </div>
        }

        {/* <div className='cards-wrapper desktop'>
            <Slider {...settings}>
                {data?.map(el => {
                     return (
                         <div className='card-wrapper'>
                         <div className='top'>
                            
                         {el.logo && <img src={require(`../../assets/icons/${el.icon}`)} />}
                             <div className='flex'>
                               <h4>1</h4>
                               <h5>2</h5>
                             </div>
                         </div>
         
                         <div className='bottom'>
                             <h4>3</h4>
                             <p>4</p>
                         </div>
                     </div>
                     )
                 })}
                 </Slider>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default SliderComponent;
