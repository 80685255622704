import { GeneralTypes } from '../actions'

const initialState = {
  innovationsRef: '',
  profilesData: [],
  usersData: [],
  fighters: {},
  managers: {},
  coaches: {},
  promoters: {},
  fans: {},
  total: 0
}

const getProfileCount = (state, payload, profile) => {
  let data = state.profilesData?.length ? state.profilesData.slice() : payload?.docs.slice()

  let index = data.findIndex(el => el?.role === profile)
  let payloadProfile = payload.docs.find(el => el?.role === profile)
  let profileData = {...data[index]}

  data?.map((obj) => {
    if (obj.role === profile) {
      profileData = { ...obj, count: payloadProfile.count }
    }
  })
  return profileData
}

const getProfileData = (state, payload) => {
  if (!state || !payload) return
  
  let data = state.profilesData?.length ? state.profilesData.slice() : payload?.docs.slice()

    payload?.docs?.map((obj) => {
        let index = data.findIndex(el => el?.role === obj.role)
        data[index].total = obj.count
    })

    return data
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case GeneralTypes.SET_REF:
      return { ...state, innovationsRef: action.payload }
    case GeneralTypes.GET_REGISTERED_USERS_SUCCESS:
      return { ...state, total: action.payload.total, usersData: action.payload.docs, profilesData: getProfileData(state, action.payload) }
    case GeneralTypes.GET_PROFILES_COUNT_SUCCESS:
      return { ...state, fighters: getProfileCount(state, action.payload, 'fighter'), managers: getProfileCount(state, action.payload, 'manager'), promoters: getProfileCount(state, action.payload, 'promoter'), fans: getProfileCount(state, action.payload, 'fan'), coaches: getProfileCount(state, action.payload, 'coach') }
    // case GeneralTypes.GET_STATISTICS_SUCCESS:
    //   return { ...state, total: action.payload.total, profilesData: action.payload.docs, fighters: { count: action.payload.docs[0].role.count } }
    default:
      return state
  }
}

export default general