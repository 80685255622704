/* eslint-disable */
import { blogTypes } from "../actions";

const initialState = {
  allBlogs: [],
  currentBlog: {},
  allPhotos:[]
};

const handleAllPhotos = (state,payload) => {
 const newState = [];
 newState.push(payload.coverPhoto);
 if(newState[0].name.includes(payload.photos[0].name)){
  return newState;
 }
 else return newState.concat(payload.photos);
}

const blogs = (state = initialState, { type, payload }) => {
  switch (type) {
    case blogTypes.ARTICLES_LIST_SUCCESS:
      return {
        ...state,
        allBlogs: payload.docs,
      };
    case blogTypes.ARTICLE_BY_ID_SUCCESS:
      return {
        ...state,
        currentBlog: payload,
        allPhotos:handleAllPhotos(state,payload)
      };
    default:
      return state;
  }
};

export default blogs;
