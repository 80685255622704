import React from "react";
import { TitleComponent, Tabs } from ".././../components";
import "./styles.scss";
import 'tailwindcss/tailwind.css';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FAQ from "../../screens/HowItWorks/info.json";
import { getStatistics, getRegisteredUsers, getProfilesCount } from "../../actions";
import moment from "moment";

const StatisticsComponent = ({
  renderContent,
  invert,
  title,
  subtitle,
  noAlign,
  profile,
  semibigger,
  fullblended,
  innerMaxHeight,
  fixedHeight,
  removeLine,
  titleId,
  reversed,
  backgroundBlack,
  mobile,
  opacity,
  height,
  titleMargin,
  smaller,
  options,
  noMargin,
  profileType,
  registeredUsers
}) => {
  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch()
  const total = useSelector(state => state.general.total)
  const profilesData = useSelector(state => state.general.profilesData)
  const usersData = useSelector(state => state.general.usersData)
  const fighters = useSelector(state => state.general.fighters)
  const coaches = useSelector(state => state.general.coaches)
  const managers = useSelector(state => state.general.managers)
  const promoters = useSelector(state => state.general.promoters)
  const data = FAQ;
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  const profileMap = {
    'fighter': fighters,
    'coach': coaches,
    'manager': managers,
    'promoter': promoters,
  }

  useEffect(() => {
    if (registeredUsers || profile) dispatch(getRegisteredUsers({ groupBy: 'role' }))
    if (profile) dispatch(getProfilesCount({ groupBy: 'role', startDate: moment().subtract(1, 'month'), endDate: moment() }))
  },[profileType])

  // useEffect(() => {
  //   if (options?.length) setSelected(Object.values(options)[0]?.name)
  // },[options])

  const renderProfileData = () => {
    if (profileType === 'fighter') {
      return (
        <div className="testimonials-wrapper">
          {data?.map(el => {
            return (
              <li className="testimonial">
              <div className="flex gap-2 mb-2">
                <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" />
                <div className="flex-col content-between gap-10px h-full text-wrapper">
                  <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Fighter</p>
                  <div className="flex-col gap-1 mt-auto">
                    <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Tanya Jones</div>
                    {isMobileVersion === 0 &&
                    <h3 className="text bold" style={{ fontSize: "18px" }}>Thank you Fight Scout</h3>}
                  </div>
                </div>
              </div>

              {isMobileVersion === 1 && <h3 className="text bold mb-4">Thank you Fight Scout</h3>}

              <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>Since I’ve joined the app, my career has grown so much. I can tell you without a doubt that registering is worth it! I’ve had 3 new matched in the last month, all recruited in the app. I’ve alsomade a connection with an amazing promoter!</p>
            </li>
            )
          })}
        </div>
      )
    }
  }

  console.log(fighters)
  console.log(coaches)
  console.log(managers)
  console.log(promoters)

  return (
    <div
      className={`section-container statistics ${invert && "inverted"}  
    ${backgroundBlack && "backgroundBlack"} ${mobile && "mobile"} ${noMargin && "no-margin"} ${opacity && "opacity"} ${fullblended && "fullblended"}`}
      style={{ height: `${height}vh` }}
    >
      <div
        className={`container content ${height && 'h-full'} ${color} ${noAlign && "no-align"} ${opacity && "opacity"} ${profile && "profile"}`}
        style={{ maxHeight: `${innerMaxHeight}%` }}
      >
        <div className={`container-inner flex-row content-start ${profile && "profile"}`} style={{height:fixedHeight && "80%"}}>

          {/* old */}
        {/* {options?.length &&
        <div className="left-container">
          {title === "" ? ( "" ) : (
              subtitle?.length && 
                <>
                  <h3 className="title">{title}</h3>
                  <h4 className="uppercase mb-3">{subtitle}</h4>
                </>
             )}

              {<div className="profiles-wrapper flex-col">
                {options?.map((data, index) => {
                  return (
                    <div className="option flex-row justify-between rounded-md content-center border-2 w-full" key={`${index}-profile`}>
                      <div className="profile-name uppercase">{data?.name}: </div>
                      <div className="profile-value uppercase">{data?.value} {data?.profiles ? 'profiles' : ''}</div>
                    </div>
                  )
                })}
              </div>}


            </div>} */}
            {/* old */}


        {/* new */}
        {(profilesData?.length && profileType) ?
          <div className="left-container">
          {title === "" ? ( "" ) : (
              subtitle?.length && 
                <>
                  <h3 className="title">{title}</h3>
                  <h4 className="uppercase mb-3">{subtitle}</h4>
                </>
             )}

              {/* {<div className="profiles-wrapper flex-col">
                {options &&
                options?.map((data, index) => {
                  return (
                    <div className="option flex-row justify-between rounded-md content-center border-2 w-full" key={`${index}-profile`}>
                      <div className="profile-name uppercase">{data?.name}: </div>
                      <div className="profile-value uppercase">{data?.name === 'Registered' ? profilesData?.find(el => el?.role === profileType)?.count : data?.value} {data?.profiles ? 'profiles' : ''}</div>
                    </div>
                  )
                })}
              </div>} */}
              
              {<div className="profiles-wrapper flex-col">
                {options &&
                options?.map((data, index) => {
                  return (
                    <div className="option flex-row justify-between rounded-md content-center border-2 w-full" key={`${index}-profile`}>
                      <div className="profile-name uppercase">{data?.name}: </div>
                      <div className="profile-value uppercase">{data?.name === 'Registered' ? profileMap[profileType]?.total : profileMap[profileType]?.count} {data?.profiles ? 'profiles' : ''}</div>
                    </div>
                  )
                })}
              </div>}
          </div> :
          registeredUsers ? 
          <div className="left-container">
          {title === "" ? ( "" ) : (
              subtitle?.length && 
                <>
                  <h3 className="title">{title}</h3>
                  <h4 className="uppercase mb-3">{subtitle}</h4>
                </>
             )}

              <div className="profiles-wrapper flex-col">
                {options?.map((data, index) => {
                  let count = usersData?.find(profile => profile.role === data.role)?.count
                  return (
                    <div className="option flex-row justify-between rounded-md content-center border-2 w-full" key={`${index}-${data?.name}user`}>
                      <div className="profile-name uppercase">{data?.name}: </div>
                      <div className="profile-value uppercase">{`${count} profiles`}</div>
                    </div>
                  )
                })}
            </div>
          </div>
            : <div className="left-container">
            {title === "" ? ( "" ) : (
                subtitle?.length && 
                  <>
                    <h3 className="title">{title}</h3>
                    <h4 className="uppercase mb-3">{subtitle}</h4>
                  </>
               )}
  
                {<div className="profiles-wrapper flex-col">
                  {options?.map((data, index) => {
                    return (
                      <div className="option flex-row justify-between rounded-md content-center border-2 w-full" key={`${index}-profile`}>
                        <div className="profile-name uppercase">{data?.name}: </div>
                        <div className="profile-value uppercase">{data?.value} {data?.profiles ? 'profiles' : ''}</div>
                      </div>
                    )
                  })}
              </div>}
          </div>}
            {/* new */}


            <div className="right-container">
                <ul className="testimonials-wrapper">
                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Fighter</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Tanya Jones</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>Thank you Fight Scout</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">Thank you Fight Scout</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>Since I’ve joined the app, my career has grown so much. I can tell you without a doubt that registering is worth it! I’ve had 3 new matched in the last month, all recruited in the app. I’ve alsomade a connection with an amazing promoter!</p>
                  </li>
                  
                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-2.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Promoter</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>FightClub - Las Vegas</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>What a great find!</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">What a great find!</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>We’ve been using the app for over a year now and let me tell you - what a change! It’s amazing for promoting events, recruiting fighters and networking. Thank you!</p>
                  </li>
                  
                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-3.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Manager</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Aiden Shaw</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>Amazing for networking!</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">Amazing for networking!</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>Amazing for networking!</p>
                  </li>

                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-4.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Coach</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Andrey Ivanov</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>Can’t complain</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">Can’t complain</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>This app has helped me connect with new fighters and promoters</p>
                  </li>

                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-5.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Fighter</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Arron Sanchez</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>What a journey</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">What a journey</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>Since I’ve joined the app, my career has grown so much. I can tell you without a doubt that registering is worth it! I’ve had 3 new matched in the last month, all recruited in the app. I’ve alsomade a connection with an amazing promoter!</p>
                  </li>

                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-6.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-red border-2 sm:border rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Fighter</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Aria Shaw</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>Amazing for networking!</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">Amazing for networking!</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>Amazing for networking!</p>
                  </li>

                  <li className="testimonial">
                    <div className="flex gap-2 mb-2">
                      {/* <img className="thumbnail" src="https://fightscout.s3.eu-central-1.amazonaws.com/images/fighter-thumbnail.png" /> */}
                      <img className="thumbnail" src={require("../../assets/images/thumbnail-7.png")} />
                      <div className="flex-col content-between gap-10px h-full text-wrapper">
                        <p className={`border-solid border-2 border-red rounded w-fit py-1 px-1 font-bold ${isMobileVersion === 1 ? 'mb-2' : 'mb-4'}`} style={{ fontSize: "12px" }}>Fighter</p>
                        <div className="flex-col gap-1 mt-auto">
                          <div className="text light" style={{ fontSize: `${isMobileVersion === 1 ? '16px' : '18px'}` }}>Anthony Vergas</div>
                          {isMobileVersion === 0 &&
                          <h3 className="text bold" style={{ fontSize: "18px" }}>What a great find!</h3>}
                        </div>
                      </div>
                    </div>

                    {isMobileVersion === 1 && <h3 className="text bold mb-4">What a great find!</h3>}

                    <p className={`text-base font-light w-fit ${isMobileVersion === 1 ? 'pl-0' : ''}`} style={{ fontSize: "16px" }}>We’ve been using the app for over a year now and let me tell you - what a change! It’s amazing for promoting events, recruiting fighters and networking. Thank you!</p>
                  </li>

                </ul>
            </div>
          {renderContent && renderContent()}
        </div>
      </div>
    </div>
  );
};

export default StatisticsComponent;
